import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, TextField } from '@mui/material';
import { formatNumber, isSuperAdmin } from '../../../../util';
import { PERIOD_STATUS, USER_TYPE_ACCESS } from '../../../../constants';
import useUser from './../../../../hook/useUser';

KpiItem.propTypes = {
    kpi : PropTypes.object,
    filters : PropTypes.object,
    periodShow : PropTypes.object,
    onChange : PropTypes.func,
};

function KpiItem(props) {

    const { kpi, onChange, periodShow, filters } = props;

    const user = useUser();

    const handleChange = (e) => {
        if (!onChange)  return;
        onChange(kpi.id, e.target.value);
    };

    const isOpen = periodShow.status === PERIOD_STATUS.OUVERT.code;
    const canWrite = isSuperAdmin() || user.typeAcces === USER_TYPE_ACCESS.LECTURE_ECRITURE.code;
    const notShowWithCroissancExterne = !kpi.croissanceExterne && !!filters.croissanceExterneCode;

    return (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell component="th" scope="row">{kpi.libelle}</TableCell>
            <TableCell align="right">{formatNumber(kpi.kpiDetail?.valeurRealise || 0)}</TableCell>
            <TableCell align="right" sx={{bgcolor : 'background.main'}}>
                {
                    (isOpen && canWrite && !notShowWithCroissancExterne && kpi.ajustable)
                    ? 
                    <TextField
                        id={`filled-number-${kpi.id}`}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="standard"
                        value={kpi.kpiDetail?.valeurAjuste || null}
                        size='small'
                        onChange={handleChange}
                    />
                    : (formatNumber(kpi.kpiDetail?.valeurAjuste || 0) || '')
                }
                </TableCell>
            <TableCell align="right">{formatNumber((kpi.kpiDetail?.valeurRealise || 0) + (kpi.kpiDetail?.valeurAjuste || 0))}</TableCell>

            <TableCell sx={{borderLeft : '1px solid!important'}} align="right">{formatNumber(kpi?.kpiDetailPrevious?.valeurRealise || 0)}</TableCell>
            <TableCell align="right">{formatNumber(kpi?.kpiDetailPrevious?.valeurAjuste || 0)}</TableCell>
            <TableCell align="right">{formatNumber((kpi.kpiDetailPrevious?.valeurRealise || 0) + (kpi.kpiDetailPrevious?.valeurAjuste || 0))}</TableCell>
        </TableRow>
    );
}

export default KpiItem;