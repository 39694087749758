import { Box, Paper } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import logo from './../../assets/images/ajustement_kpi_2.png';

Logo.propTypes = {
};

function Logo() {

    return (
        <RouterLink to="/">
            <Box position="fixed" zIndex={2000} top={0} left={0} right="auto" sx={{marginLeft : 2, bgcolor : 'white'}}>
                <Paper elevation={1} sx={{padding : 1}}>
                    <img src={logo} alt="Ajustement KPI" height={50} />
                </Paper>
            </Box>
        </RouterLink>
    );
}

export default Logo;