import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Hidden, IconButton, Toolbar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Profile from '../../features/Profile';

Header.propTypes = {
    onToggle : PropTypes.func,
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        flexGrow : 1,
        backgroundColor : `${theme.palette.background.main}!important`,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        boxShadow: 'none!important',
        borderBottomWidth: 'thin',
        borderStyle: 'solid',
        borderColor: '#EAEEF3',
    },
}));

function Header(props) {

    const classes = useStyles();
    const { onToggle } = props;

    const handleClick = () => {
        if (!onToggle)   return;
        onToggle();
    };

    return (
        <AppBar position="fixed" className={classes.appBar} nowrap="true">
            <Toolbar sx={{justifyContent : 'flex-end'}}>
                <Profile />
                <Hidden smUp>
                    <Tooltip title="Ouvrir menu">
                        <IconButton color="inherit" aria-label="Ouvrir menu" edge="start" onClick={handleClick} sx={{marginRight : 2, marginLeft : 0.5}} >
                            <MenuIcon sx={{color : 'button.main'}} />
                        </IconButton>
                    </Tooltip>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

export default Header;