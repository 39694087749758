import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import DesktopDate from '../../../../components/form-controls/DateField/DesktopDate';
import InputField from '../../../../components/form-controls/InputField';

MetierFormModif.propTypes = {
    metier : PropTypes.object,
    onSubmit : PropTypes.func,
};

function MetierFormModif(props) {

    const { metier, onSubmit } = props;

    const schema = yup.object().shape({
        dateDebut: yup.date().nullable(),
        dateFin: yup.date().nullable(),
        libelle: yup.string(),
    });

    const form = useForm({
        defaultValues: {
            libelle : metier.libelle,
            dateDebut: metier.dateDebut ? new Date(metier.dateDebut) : null,
            dateFin: metier.dateFin ? new Date(metier.dateFin) : null,
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        if (!onSubmit)  return;
        onSubmit(metier.uuid, values);
    };

    return (
        <Box sx={{marginTop : 2, fontWeight : 'bold', fontSize : '20px'}}>
            <form onSubmit={form.handleSubmit(handleSubmit)} sx={{margin : 1}} id="form-modify" >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <InputField name="libelle" label="Libéllé du métier" form={form} variant="outlined"/>
                            </Grid>
                            <Grid item xs={12}>
                                <DesktopDate name="dateDebut" label="Date d'effet" form={form} variant="outlined"/>
                            </Grid>
                            <Grid item xs={12}>
                                <DesktopDate name="dateFin" label="Date de fin d'effet" form={form} variant="outlined"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Code" variant="outlined" size="small" fullWidth disabled value={metier.code} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display="none">
                        <Button variant="contained" color="primary" type="submit" id="form-submit"> Enregistrer </Button>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}

export default MetierFormModif;