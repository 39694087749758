import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import DesktopDate from '../../../../components/form-controls/DateField/DesktopDate';
import InputField from '../../../../components/form-controls/InputField';

MetierFormAdd.propTypes = {
    onSubmit : PropTypes.func,
};

function MetierFormAdd(props) {

    const { onSubmit } = props;

    const schema = yup.object().shape({
        code: yup.string().required('Veuillez saisir l\'identifiant'),
        libelle: yup.string().nullable(),
        dateDebut: yup.date().required('Veuillez saisir la date d\'effet'),
        dateFin: yup.date().nullable(),
    });

    const form = useForm({
        defaultValues: {
            dateDebut : null,
            dateFin : null,
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        if (!onSubmit)  return;
        onSubmit({ ...values });
    };

    return (
        <Box sx={{marginTop : 2, fontWeight : 'bold', fontSize : '20px'}}>
            <form onSubmit={form.handleSubmit(handleSubmit)} sx={{margin : 1}} id="form-modify" >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <InputField name="code" label="Code du métier" form={form} variant="outlined"/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputField name="libelle" label="Libéllé du métier" form={form} variant="outlined"/>
                    </Grid>
                    <Grid item xs={12}>
                        <DesktopDate name="dateDebut" label="Date d'effet" form={form} variant="outlined"/>
                    </Grid>
                    <Grid item xs={12}>
                        <DesktopDate name="dateFin" label="Date de fin d'effet" form={form} variant="outlined"/>
                    </Grid>
                    <Box display="none">
                        <Button variant="contained" color="primary" type="submit" id="form-submit"> Enregistrer </Button>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}

export default MetierFormAdd;