import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import axiosClient from '../api/axiosClient';
import otherApi from '../api/otherApi';

export default function(){
  const [ conf, setConf ] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
        const { data } = await otherApi.getConfig();
        axiosClient.defaults.baseURL = data.API_END_POINT;
        setConf(data);
      } catch (error) {
        console.log(error);
        enqueueSnackbar('Ne peut pas télécharger la configuration', { variant: 'error' });
      }
    })();
  }, []);

  return conf;
}