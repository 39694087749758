import { Chip, TableCell, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Replay from '@mui/icons-material/Replay';
import MobiledataOff from '@mui/icons-material/MobiledataOff';
import MenuMore from '../../../../components/MenuMore';
import { formatDateTime } from '../../../../util';

KpiParamItem.propTypes = {
    kpiParam : PropTypes.object,
    onToggle : PropTypes.func,
};

function KpiParamItem(props) {

    const { kpiParam, onToggle } = props;
    const { palette : { button, tertiary } } = useTheme();

    const handleEdit = (values, action) => {
        if (!onToggle)   return;
        onToggle(kpiParam, values, action);
    };

    const OPTIONS = [
        { title : 'Report M-1', onClick : () => handleEdit({reportable : true}, 'REPORTABLE'), icon : Replay, color: button.main, show : !kpiParam.reportable },
        { title : 'Pas de report M-1', onClick : () => handleEdit({reportable : false}, 'NOT_REPORTABLE'), icon : MobiledataOff, color: tertiary.main, show : kpiParam.reportable },
    ];

    return (
        <TableRow hover>
            <TableCell>{kpiParam.kpiLibelle}</TableCell>
            <TableCell>{kpiParam.metierCode}</TableCell>
            <TableCell>
                <Chip
                    key={`report-${kpiParam.kpiCode}`}
                    label={kpiParam.reportable ? 'Oui' : 'Non' }
                    size="small"
                    color={kpiParam.reportable ? 'success' : 'warning'}
                />
            </TableCell>
            <TableCell>{kpiParam.modifiePar}</TableCell>
            <TableCell>{formatDateTime(kpiParam.modifieLe)}</TableCell>
            <TableCell align="center">
                <MenuMore options={OPTIONS} />
            </TableCell>
        </TableRow>
    );
}

export default KpiParamItem;