import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/params';

const paramApi = {
    async listHierarchie(){
        const url = `${BASE_URL}/listHierarchie`;
        return axiosClient.get(url);
    },
    async listHierarchieWith(uuid){
        const url = `${BASE_URL}/listHierarchie/${uuid}`;
        return axiosClient.get(url);
    },
    async searchHierarchie(searchKey){
        const url = `${BASE_URL}/searchHierarchie?searchKey=${searchKey}`;
        return axiosClient.get(url);
    },
    async listSociete(periodUuid, searchKey = ''){
        const url = `${BASE_URL}/listSociete?periodUuid=${periodUuid}&searchKey=${searchKey}`;
        return axiosClient.get(url);
    },
    async listCentreCout(periodUuid, societeCode, metierCode, searchKey = ''){
        const url = `${BASE_URL}/listCentreCout?periodUuid=${periodUuid}&societeCode=${societeCode}&metierCode=${metierCode}&searchKey=${searchKey}`;
        return axiosClient.get(url);
    },
    async listCroissanceExterne(periodUuid){
        const url = `${BASE_URL}/listCroissanceExterne?periodUuid=${periodUuid}`;
        return axiosClient.get(url);
    },
    async listParams(){
        const url = `${BASE_URL}/listParametrage`;
        return axiosClient.get(url);
    },
    async addParams(params){
        const url = `${BASE_URL}/addParametrage`;
        return axiosClient.put(url, params);
    },
    async editParams(id, params){
        const url = `${BASE_URL}/editParametrage/${id}`;
        return axiosClient.post(url, params);
    },
    async delParams(id){
        const url = `${BASE_URL}/deleteParametrage/${id}`;
        return axiosClient.delete(url);
    },
    async listKpiParams(metierCode){
        return axiosClient.get(`${BASE_URL}/listKpiParametrage`, {params: {metierCode}});
    },
    async postKpiParams(params){
        return axiosClient.post(`${BASE_URL}/kpiParametrage`, params);
    },
};

export default paramApi;