import { Box, Grid, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import Loading from '../../../components/Loading';
import Popup from '../../../components/Popup';
import SkeletonList from '../../../components/Skeleton/List';
import paramApi from './../../../api/paramApi';
import metierApi from './../../../api/metierApi';
import KpiParamsList from './components/KpiParamsList';
import { getMessageError } from '../../../util';
import KpiParamFilter from './components/KpiParamFilter';

function KpiParamsDisplayList() {

    const { enqueueSnackbar } = useSnackbar();

    const [ kpiParamsList, setKpiParamsList ] = useState([]);
    const [ codeMetierList, setCodeMetierList ] = useState([]);
    const [ filters ] = useState({page: 0, size: 100, params: {}});

    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});

    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        getCodeMetierList();
        /* getKpiParamsList(); */
    }, []);

    const getCodeMetierList = async () => {
        setLoading(true);
        try {
            const { items } = await metierApi.list(filters);
            setCodeMetierList(items);
        } catch (error) {
            const msg = getMessageError(error, 'Échec du chargement des code métier.');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const getKpiParamsList = async (codeMetier) => {
        setLoading(true);
        try {
            const data = await paramApi.listKpiParams(codeMetier);
            setKpiParamsList(data);
        } catch (error) {
            const msg = getMessageError(error, 'Échec du chargement des paramétrages KPI.');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newCodeMetier) => {
        getKpiParamsList(newCodeMetier);
    };

    return (<>
        {loading && <Loading/>}
        <Box>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Box sx={{marginTop : 5, fontWeight : 'bold', fontSize : '20px'}}>
                        <Paper elevation={1} sx={{padding : 2}}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography component="span" variant="h6" sx={{fontWeight: 'bold', color : 'paragraph.main'}}>{'Gestion des paramétrages KPI'}</Typography>
                            </Box>
                            <KpiParamFilter filters={filters} codeMetierList={codeMetierList} onChange={handleFilterChange} />
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <Paper elevation={1} sx={{padding : 2}}>
                        {loading 
                        ?
                            <SkeletonList />
                        : <>
                            <KpiParamsList data={kpiParamsList} callback={getKpiParamsList} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} />
                          </>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        <Popup open={openPopup} {...paramPopup} />
    </>);
}

export default KpiParamsDisplayList;