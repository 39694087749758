import { Box, Button, Grid, Paper, TablePagination, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Loading from '../../../components/Loading';
import Popup from '../../../components/Popup';
import SkeletonList from '../../../components/Skeleton/List';
import useUser from '../../../hook/useUser';
import { getMessageError } from '../../../util';
import userApi from './../../../api/userApi';
import ExportExcel from './components/ExportExcel';
import UserAdd from './components/UserAdd';
import UserFilter from './components/UserFilter';
import UserList from './components/UserList';

User.propTypes = {
};

const ROW_OF_PAGE_DEFAULT = 10;

function User() {

    const user = useUser();
    const { enqueueSnackbar } = useSnackbar();

    const [ userList, setUserList ] = useState([]);
    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});

    const location = useLocation();
    const history = useHistory();

    const queryParam = useMemo(() => {
        const params = queryString.parse(location.search);
  
        return {
          ...params,
          page : Number.parseInt(params.page) || 0,
          size : Number.parseInt(params.size) || ROW_OF_PAGE_DEFAULT,
        };
    }, [location.search]);

    const [ rowsPerPage, setRowsPerPage ] = useState(queryParam.size || ROW_OF_PAGE_DEFAULT);
    const [ pagination, setPagination ] = useState({ page : 0, count : 1 });
    const [ loading, setLoading ] = useState(false);
    const [ filters, setFilters ] = useState(() => ({ 
        page : 0, size : queryParam.size || ROW_OF_PAGE_DEFAULT,
        nom : queryParam.nom || '',
        prenom : queryParam.prenom || '',
        identifiant : queryParam.identifiant || '',
        email : queryParam.email || '',
    }));

    useEffect(() => {
        getListUser();
    }, [ queryParam ]);

    const getListUser = async () => {
        setLoading(true);
        try {
            const { page, size, ...params } = queryParam;
            const { items, totalItems, currentPage } = await userApi.list({ page, size, params });
            setUserList(items);
            setPagination({ count : totalItems, page : currentPage });
        } catch (error) {
            const msg = getMessageError(error, 'Échec de l\'obtention de l\'utilisateur de la liste, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilter) => {
        setFilters({...filters, ...newFilter });
    };

    const pushNewParams = newfilters => {
        history.push({
            pathname : history.location.pathname,
            search : queryString.stringify(newfilters),
        });
    };

    const handlePageChange = (e, page) => {
        const newfilters = {...queryParam, page };
        pushNewParams(newfilters);
    };

    const handleClick = () => {
        const newfilters = {...queryParam, page : 0, ...filters };
        pushNewParams(newfilters);
    };

    const handleChangeRowsPerPage = (event) => {
        const size = parseInt(event.target.value, 10);
        const newfilters = {...queryParam, size, page : 0 };
        pushNewParams(newfilters);
        setRowsPerPage(size);
    };

    const handleInit = () => {
        setFilters({ ...filters, nom : '', prenom : '', identifiant : '', email : ''});
    };

    return (<>
        { loading && <Loading/> }
        <Box>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Box sx={{marginTop : 5, fontWeight : 'bold', fontSize : '20px'}}>
                        <Paper elevation={1} sx={{padding : 2}}>
                            <Box display="flex" justifyContent="space-between">
                                <Box display="flex">
                                    <Typography component="span" variant="h6" sx={{fontWeight: 'bold', color : 'paragraph.main', marginRight : 1}}>{'Recherche d\'un utilisateur'}</Typography>
                                    <ExportExcel />
                                </Box>
                                
                                <UserAdd callback={getListUser} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} user={user} />
                            </Box>
                            <UserFilter filters={filters} onChange={handleFilterChange} />
                            <Box display="flex" justifyContent="flex-end" sx={{marginRight : 2}}>
                                <Button onClick={handleClick} sx={{bgcolor: 'button.main', textTransform: 'none', color: 'buttonText.main', fontWeight : 'bold', '&:hover' : {bgcolor: 'button.main'}, marginRight : 1}} variant="contained" color="primary" size="small">
                                    Recherche
                                </Button>
                                <Button onClick={handleInit} sx={{bgcolor: 'button.default', textTransform: 'none', color: 'buttonText.main', fontWeight : 'bold', '&:hover' : {bgcolor: 'button.default'}}} variant="contained" color="primary" size="small">
                                    Réinitialiser
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <Paper elevation={1} sx={{padding : 2}}>
                        {loading 
                        ? <SkeletonList /> 
                        : <>
                            <UserList data={userList} callback={getListUser} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} />
                            <Box display="flex" flexFlow="row nowrap" justifyContent="center" sx={{marginTop : 2.5, paddingBottom : 1}} >
                                <TablePagination 
                                    rowsPerPageOptions={[3, 10, 20, 50]}
                                    component="div"
                                    count={pagination.count}
                                    rowsPerPage={rowsPerPage}
                                    page={pagination.page}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage="Nombre de ligne sur page"
                                />
                            </Box>
                        </>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        <Popup open={openPopup} {...paramPopup} />
    </>);
}

export default User;