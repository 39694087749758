import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import PrivateRoute from './components/Router/PrivateRoute';
import Login from './features/Auth/Login';
import { getOktaAuthConfig, getOktaSignInConfig } from './util';
import useConfig from './hook/useConfig';
import './App.css';

function App() {

  const history = useHistory();
  const config = useConfig();

  if (!config)  return (<></>);

  const oktaAuth = new OktaAuth(getOktaAuthConfig(config));

  const customAuthHandler = () => {
    history.push('/login');
  };

  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    originalUri && history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <>
      {
        config && 
        <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={restoreOriginalUri}>
          <Switch>
            <Route path="/login" render={() => <Login config={getOktaSignInConfig(config)} />}/>
            <Route path="/login/callback" component={LoginCallback} />
            <Route component={ PrivateRoute } />
          </Switch>
        </Security>
      }
    </>
  );
}

export default App;
