import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { fr } from 'date-fns/locale';
import MultipleSelect from './../../../../components/form-controls/MultipleSelect';
import { JOB_PERIOD } from '../../../../constants';

JobFilter.propTypes = {
    onChange : PropTypes.func,
    filters : PropTypes.object,
    nomJobList : PropTypes.array,
};

function JobFilter(props) {

    const { onChange, filters, nomJobList } = props;

    const [ fields, setFields ] = useState({
        begin : { name : 'begin', value : null },
        end : { name : 'end', value : null },
        nomJob : { name : 'nomJob', value : ' ' },
        status : { name : 'status', value : Object.values(JOB_PERIOD) },
    });

    useEffect(() => {
        const newFields = { ...fields};
        Object.keys(newFields).forEach(key => {
            newFields[key].value = filters[key];
        });
        if (typeof filters.status === 'string'){
            newFields['status'].value = [ JOB_PERIOD[filters.status] ];
        } else if (typeof filters.status === 'object') {
            newFields['status'].value = filters.status.map(s => JOB_PERIOD[s]);
        }
        setFields(newFields);
    }, [filters]);

    const handleChange = (e) => {
        if (!onChange)   return;
        const { name, value } = e.target;
        onChange({ [name] : value });
    };

    const handleMultipleSelectChange = (e) => {
        handleChange({ target : { name : 'status', value : e.target.value.map(item => item.value) } });
    };

    return (
        <Grid container spacing={2} sx={{paddingTop : 3, paddingX : 2}}>
            <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel shrink id="select-label-nom-job">Nom de traitement</InputLabel>
                    <Select name='nomJob' labelId="select-label-nom-job" id="select-nom-job" value={fields['nomJob'].value} fullWidth variant="outlined" label="Nom de traitement" size="small" onChange={handleChange}>
                        <MenuItem value={' '}><em>{'[Tous les traitements]'}</em></MenuItem>
                        { nomJobList.length && nomJobList.map((item, index) => <MenuItem key={index} value={item.nom}>{item.libelleTraitement}</MenuItem>) }
                    </Select>
                </FormControl>
            </Grid>
            <Grid item md={12} lg={6} sx={{width : '100%'}}>
                <MultipleSelect name="status" label="Statut" list={Object.values(JOB_PERIOD)} onChange={handleMultipleSelectChange} listDefault={fields['status'].value} />
            </Grid>
            <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                    <Stack sx={{width : '100%'}}>
                        <DesktopDatePicker
                            label="Date début période"
                            id="begin"
                            inputFormat="dd/MM/yyyy"
                            onChange={(date) => {
                                handleChange({target : { name : 'begin', value : date ? date.getTime() : null }});
                            }}
                            value={fields['begin'].value}
                            renderInput={(params) => <TextField {...params} size="small" defaultValue="" />}
                        />
                    </Stack>
                </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                    <Stack sx={{width : '100%'}}>
                        <DesktopDatePicker
                            label="Date fin période"
                            id="end"
                            inputFormat="dd/MM/yyyy"
                            onChange={(date) => {
                                handleChange({target : { name : 'end', value : date ? date.getTime() : null }});
                            }}
                            value={fields['end'].value}
                            renderInput={(params) => <TextField {...params} size="small" defaultValue="" />}
                        />
                    </Stack>
                </LocalizationProvider>
            </Grid>
        </Grid>
    );
}

export default JobFilter;