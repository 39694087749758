import BlockIcon from '@mui/icons-material/Block';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SwipeIcon from '@mui/icons-material/Swipe';
import { TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import MenuMore from '../../../../components/MenuMore';
import { PERIOD_STATUS } from '../../../../constants';
import { formatDate, translateDate } from '../../../../util';

PeriodItem.propTypes = {
    period : PropTypes.object,
    onDelete : PropTypes.func,
    onEdit : PropTypes.func,
    onChargerManuel : PropTypes.func,
};

function PeriodItem(props) {

    const { period, onDelete, onEdit, onChargerManuel } = props;
    const { palette : { button, tertiary } } = useTheme();


    const OPTIONS = [
        { title : 'Modifier', onClick : () => onEdit(period), icon : LockOpenIcon, color: button.main, show : true },
        { title : 'Supprimer', onClick : () => onDelete(period), icon : BlockIcon, color: tertiary.main, show : period.status === PERIOD_STATUS.CREEE.code },
        { title : 'Charger le référentiel manuel', onClick : () => onChargerManuel(period), icon : SwipeIcon, color: button.main, show : period.status === PERIOD_STATUS.OUVERT.code && !period.chargee },
    ];

    return (
        <TableRow hover>
            <TableCell>{translateDate(period.libelle)}</TableCell>
            <TableCell>{formatDate(period.dateDebut)}</TableCell>
            <TableCell>{formatDate(period.dateFin)}</TableCell>
            <TableCell>{formatDate(period.dateOuverture)}</TableCell>
            <TableCell>{formatDate(period.dateCloture)}</TableCell>
            <TableCell>{PERIOD_STATUS[period.status]?.label}</TableCell>
            <TableCell align="center">
                <MenuMore options={OPTIONS} />
            </TableCell>
        </TableRow>
    );
}

export default PeriodItem;