import BlockIcon from '@mui/icons-material/Block';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import MenuMore from '../../../../components/MenuMore';
import { formatDate } from '../../../../util';
import EditIcon from '@mui/icons-material/Edit';

MetierItem.propTypes = {
    metier : PropTypes.object,
    onEdit : PropTypes.func,
    onToggle : PropTypes.func,
};

function MetierItem(props) {

    const { metier, onEdit, onToggle } = props;
    const { palette : { button, tertiary } } = useTheme();

    const handleEdit = (values, action) => {
        if (!onToggle)   return;
        onToggle(metier, values, action);
    };

    const OPTIONS = [
        { title : 'Modifier', onClick : () => onEdit(metier), icon : EditIcon, color: button.main, show : true },
        { title : 'Activer', onClick : () => handleEdit({active : true}, 'ACTIVER'), icon : LockOpenIcon, color: button.main, show : !metier.active },
        { title : 'Désactiver', onClick : () => handleEdit({active : false}, 'DESACTIVER'), icon : BlockIcon, color: tertiary.main, show : metier.active },
    ];

    return (
        <TableRow hover>
            <TableCell>{metier.code}</TableCell>
            <TableCell>{metier.libelle}</TableCell>
            <TableCell>{formatDate(metier.dateDebut)}</TableCell>
            <TableCell>{formatDate(metier.dateFin)}</TableCell>
            <TableCell>{metier.active ? 'Activé' : 'Désactivé'}</TableCell>
            <TableCell align="center">
                <MenuMore options={OPTIONS} />
            </TableCell>
        </TableRow>
    );
}

export default MetierItem;