const STORAGES_KEYS = {
    USER : 'user',
    TOKEN : 'access_token'
};

const PROFILES_CODES = {
    SUPER_ADM : 'SUPER_ADMINISTRATEUR',
    ADMINISTRATOR : 'ADMINISTRATOR',
    UTILISATEUR : 'UTILISATEUR_STANDARD',
};

const PROFILES_LABELS = {
    SUPER_ADMINISTRATEUR : 'Super administrateur',
    ADMINISTRATOR : 'Administrateur',
    UTILISATEUR_STANDARD : 'Utilisateur',
};

const PERIOD_STATUS = {
    CREEE : { code : 'CREEE', label : 'Créée', value : 'CREEE' },
    OUVERT : { code : 'OUVERT', label : 'Ouvert', value : 'OUVERT' },
    CLOTURE : { code : 'CLOTURE', label : 'Cloturé', value : 'CLOTURE' },
};

const USER_TYPE_ACCESS = {
    LECTURE_SEUL : { code : 'LECTURE_SEUL', label : 'Lecture seule', value : 'LECTURE_SEUL' },
    LECTURE_ECRITURE : { code : 'LECTURE_ECRITURE', label : 'Lecture et écriture', value : 'LECTURE_ECRITURE' },
};

const JOB_PERIOD = {
    A_TRAITER : { code : 'A_TRAITER', label : 'A traiter', value : 'A_TRAITER'},
    EN_COURS : { code : 'EN_COURS', label : 'En cours', value : 'EN_COURS'},
    TRAITE : { code : 'TRAITE', label : 'Traité', value : 'TRAITE'},
};

const NOM_JOB_LIST = 'NOM_JOB_LIST';
const NB_DECALAGE_X_CODE = 'x';
const NB_DECALAGE_Y_CODE = 'y';

export { PROFILES_CODES, PROFILES_LABELS, PERIOD_STATUS, USER_TYPE_ACCESS, STORAGES_KEYS, JOB_PERIOD, NOM_JOB_LIST, NB_DECALAGE_X_CODE, NB_DECALAGE_Y_CODE };
