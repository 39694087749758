import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import userApi from '../../../../api/userApi';
import Loading from '../../../../components/Loading';
import { getMessageError } from '../../../../util';
import UserFormEdit from './UserFormEdit';
import UserItem from './UserItem';

UserList.propTypes = {
    data : PropTypes.array,
    callback : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
};

const getPopupContent = (user, action) => {
    switch (action){
        case 'ACTIVER' : return <>{'Vous êtes sûr d\'activer '} <b>{user.identifiant}</b> </>;
        case 'DESACTIVER' : return <>{'Vous êtes sûr de désactiver '} <b>{user.identifiant}</b></>;
        default : '';
    }
};

function UserList(props) {
    const { data = [], callback, setOpenPopup, setParamPopup } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false);

    const handleModify = (user) => {
        setOpenPopup(true);
        setParamPopup({
            title : <>{'Modifier l\'utilisateur'} <b>{user.prenom} {user.nom}</b></>,
            form : <UserFormEdit onClick={editUser} user={user} />,
            buttons : [
                {label : <label htmlFor='form-submit'>Mettre à jour</label>, color : 'button.main'},
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ],
        });
    };

    const handleChangeUser = (user, values, action) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Information',
            content : getPopupContent(user, action),
            buttons : [
                {label : 'Mettre à jour', color : 'button.main', onClick : () => editUser(user, values)},
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const editUser = async (user, values) => {
        setLoading(true);
        try {
            await userApi.edit(values, user.identifiant);
            enqueueSnackbar('Mettre à jour l\'information avec succès.', { variant: 'success' });
            callback && callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en mise à jour cet utilisateur, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
            setOpenPopup(false);
            setParamPopup({});
        }
    };

    return (<>
        { loading && <Loading/> }
        <TableContainer component={Paper}>
            <Table sx={{minWidth : 650}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight : 'bold'}}>Nom</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Prénom</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Email</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Identifiant</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Profil</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>{'Type d\'accès'}</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>{'Périmètre d\'accès'}</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Statut</TableCell>
                        <TableCell align="center" sx={{fontWeight : 'bold'}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Boolean(data.length) && data.map((user, index) => (
                            <UserItem key={index} user={user} onEdit={handleModify} onChange={handleChangeUser} />
                        ))
                    }
                    {
                        Boolean(!data.length) && (
                            <TableRow hover >
                                <TableCell colSpan={9} sx={{textAlign : 'center'}}>Aucune utilisateur trouvé</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>);
}

export default UserList;