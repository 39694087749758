import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/metier';

const metierApi = {
    async list(params){
        const url = `${BASE_URL}/list`;
        return axiosClient.post(url, params);
    },
    async new(params){
        const url = `${BASE_URL}/new`;
        return axiosClient.post(url, params);
    },
    async edit(uuid, params){
        const url = `${BASE_URL}/edit/${uuid}`;
        return axiosClient.post(url, params);
    },
    async listWithPeriod(periodUuid){
        const url = `${BASE_URL}/listWithPeriod/${periodUuid}`;
        return axiosClient.get(url);
    },
};

export default metierApi;