import BlockIcon from '@mui/icons-material/Block';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import MenuMore from '../../../../components/MenuMore';

ParamItem.propTypes = {
    param : PropTypes.object,
    onDelete : PropTypes.func,
    onEdit : PropTypes.func,
};

function ParamItem(props) {

    const { param, onDelete, onEdit } = props;
    const { palette : { button, tertiary } } = useTheme();


    const OPTIONS = [
        { title : 'Modifier', onClick : () => onEdit(param), icon : LockOpenIcon, color: button.main, show : true },
        { title : 'Supprimer', onClick : () => onDelete(param), icon : BlockIcon, color: tertiary.main, show : true },
    ];

    return (
        <TableRow hover>
            <TableCell>{param.code}</TableCell>
            <TableCell>{param.value}</TableCell>
            <TableCell>{param.unite}</TableCell>
            <TableCell>{param.description}</TableCell>
            <TableCell align="center">
                <MenuMore options={OPTIONS} />
            </TableCell>
        </TableRow>
    );
}

export default ParamItem;