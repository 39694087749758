import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

SiteFilter.propTypes = {
    filters : PropTypes.object,
    updateFilter : PropTypes.func,
    hasError : PropTypes.bool,
    isDisabled : PropTypes.bool,
    forceReset : PropTypes.bool,
};

function SiteFilter(props) {

    const { filters, updateFilter, hasError = false, isDisabled = true, forceReset } = props;
    const { centreCout = {} } = filters;

    const [ itemSelected, setItemSelected ] = useState(' ');

    useEffect(() => {
        if (forceReset)  setItemSelected(' ');
    }, [ forceReset ]);
    
    useEffect(() => {
        setItemSelected(' ');
    }, [ filters.periodUuid, filters.societeCode, filters.metierCode, filters.centreCout ]);

    const handleChange = (e) => {
        setItemSelected(e.target.value);
        if (!updateFilter)  return;
        updateFilter({ siteCode : e.target.value });
    };

    return (
        <FormControl variant="outlined" fullWidth margin="dense" error={hasError}>
            <InputLabel shrink id="select-label-site">Site</InputLabel>
            <Select disabled={isDisabled} labelId="select-label-site" id="select-site" fullWidth variant="outlined" label="Site" size="small" onChange={handleChange} value={itemSelected}>
                { centreCout && centreCout.sites && centreCout.sites.length
                    ? centreCout.sites.map((item, index) => <MenuItem key={index} value={item.codeSiFinance}>{item.codeSiFinance} - {item.libelle}</MenuItem>) 
                    : <MenuItem value={' '}>Aucun site</MenuItem>
                }
            </Select>
            { hasError && <FormHelperText>Sélection obligatoire</FormHelperText> }
        </FormControl>
    );
}

export default SiteFilter;