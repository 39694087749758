import { Drawer, Hidden, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import DrawerItem from './DrawerItem';

Sidebar.propTypes = {
  onToggle : PropTypes.func,
  open : PropTypes.bool,
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('sm')] : {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper : {
    width: drawerWidth,
    backgroundColor : `${theme.palette.buttonText.main}!important`,
  },
}));

function Sidebar(props) {

  const classes = useStyles();
  const theme = useTheme();

  const { open, onToggle } = props;

  const handleClose = () => {
    if (!onToggle)  return;
    onToggle();
  };

  const container = window !== undefined ? () => window.document.body : undefined;

  return (
      <nav className={classes.drawer}>
        <Hidden smUp={true} implementation="css">
          <Drawer
              container={container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={open}
              onClose={handleClose}
              classes={{ paper: classes.drawerPaper }}
              ModalProps={{ keepMounted: true }}
          >
            <DrawerItem isToggle={true} onClose={handleClose} />
          </Drawer>
        </Hidden>
        <Hidden smDown={true} implementation="css">
          <Drawer classes={{ paper: classes.drawerPaper }} variant="permanent" open>
            <DrawerItem isToggle={false} />
          </Drawer>
        </Hidden>
      </nav>
  );
}

export default Sidebar;