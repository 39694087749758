import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import paramApi from '../../../../api/paramApi';
import { getMessageError } from '../../../../util';

CroissanceExterneFilter.propTypes = {
    filters : PropTypes.object,
    updateFilter : PropTypes.func,
    forceReset : PropTypes.bool,
};

function CroissanceExterneFilter(props) {

    const { filters, updateFilter, forceReset } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [ lst, setLst ] = useState([]);
    const [ itemSelected, setItemSelected ] = useState(null);

    useEffect(() => {
        if (forceReset){
            setItemSelected(null);
            // updateFilter({ croissanceExterneCode : null });
        }  
    }, [ forceReset ]);

    useEffect(() => {
        setItemSelected(null);

        (async () => {
            if (filters.periodUuid){
                try {
                    const lst = await paramApi.listCroissanceExterne(filters.periodUuid);
                    setLst(lst);
                } catch (error) {
                    const msg = getMessageError(error, 'Échec de l\'obtention de liste des croissance externe, veuilez contacter avec le support');
                    enqueueSnackbar(msg, { variant: 'error' });
                } 
            }
        })();
    }, [ filters.periodUuid ]);

    const handleChange = (e) => {
        setItemSelected(e.target.value);
        if (!updateFilter)  return;
        updateFilter({ croissanceExterneCode : e.target.value });
    };

    return (
        <FormControl variant="outlined" fullWidth margin="dense">
            <InputLabel shrink id="select-label-croissance-externe">Croissance externe</InputLabel>
            <Select labelId="select-label-croissance-externe" id="select-croissance-externe" value={itemSelected} fullWidth variant="outlined" label="Croissance externe" size="small" onChange={handleChange}>
                <MenuItem value={null}><em>{'[Aucune]'}</em></MenuItem>
                { lst.length && lst.map((item, index) => <MenuItem key={index} value={item.code}>{item.libelle}</MenuItem>) }
            </Select>
        </FormControl>
    );
}

export default CroissanceExterneFilter;