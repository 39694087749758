import { Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

UserFilter.propTypes = {
    onChange : PropTypes.func,
    filters : PropTypes.object,
};

function UserFilter(props) {

    const { onChange, filters } = props;
    const [ fields, setFields ] = useState({
        nom : { name : 'nom', label : 'Nom', value : null },
        prenom : { name : 'prenom', label : 'Prénom', value : null },
        identifiant : { name : 'identifiant', label : 'Identifiant', value : null },
        email : { name : 'email', label : 'Email', value : null },
    });

    useEffect(() => {
        const newFields = { ...fields};
        Object.keys(newFields).forEach(key => {
            newFields[key].value = filters[key];
        });
        setFields(newFields);
    }, [filters]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        onChange && onChange({ [name] : value });
    };

    return (
        <Grid container spacing={3} sx={{paddingTop : 3, paddingX : 2}}>
            {
                Object.values(fields).map((item, index) => (
                    <Grid key={index} item container spacing={2} xs={12} md={6} lg={3}>
                        <TextField
                            id={`input-${item.name}`}
                            label={item.label}
                            fullWidth
                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            variant="outlined"
                            name={item.name}
                            onChange={handleChange}
                            size="small"
                            value={item.value}
                        />
                    </Grid>
                ))
            }
        </Grid>
    );
}

export default UserFilter;