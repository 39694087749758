import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Popup from '../../../components/Popup';
import CentreCoutFilter from './components/CentreCoutFilter';
import CroissanceExterneFilter from './components/CroissanceExterneFilter';
import MetierFilter from './components/MetierFilter';
import PeriodFilter from './components/PeriodFilter';
import SiteFilter from './components/SiteFilter';
import SocieteFilter from './components/SocieteFilter';

Filter.propTypes = {
    onChange : PropTypes.func,
    filters : PropTypes.object,
    checkError : PropTypes.object,
    forceReset : PropTypes.bool,
};

function Filter(props) {

    const { onChange, filters, checkError = {}, forceReset } = props;

    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});

    const updateFilter = (newFilter) => {
        if (!onChange)  return;
        onChange(newFilter);
    };

    return (<>
        <Grid container spacing={1}>
            <Grid item xs={6}>
                <PeriodFilter updateFilter={updateFilter} hasError={checkError.periodUuid} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} forceReset={forceReset} />
            </Grid>
            <Grid item xs={6}>
                <MetierFilter filters={filters} updateFilter={updateFilter} hasError={checkError.metierCode} forceReset={forceReset} />
            </Grid>
            <Grid item xs={6}>
                <SocieteFilter filters={filters} updateFilter={updateFilter} hasError={checkError.societeCode} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} forceReset={forceReset} />
            </Grid>
            <Grid item xs={6}>
                <CentreCoutFilter filters={filters} updateFilter={updateFilter} hasError={checkError.centreCout} isDisabled={!(filters.periodUuid && filters.metierCode && filters.societeCode)} forceReset={forceReset} />
            </Grid>
            <Grid item xs={6}>
                <SiteFilter filters={filters} updateFilter={updateFilter} hasError={checkError.siteCode} isDisabled={!filters.centreCout} forceReset={forceReset} />
            </Grid>
            <Grid item xs={6}>
                <CroissanceExterneFilter filters={filters} updateFilter={updateFilter} forceReset={forceReset} />
            </Grid>
        </Grid>
        <Popup open={openPopup} {...paramPopup} />
    </>);
}

export default Filter;