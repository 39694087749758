import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { PROFILES_CODES, USER_TYPE_ACCESS } from '../../../../constants';
import { isSuperAdmin } from '../../../../util';
import UserAccess from './UserAccess';

UserFormEdit.propTypes = {
    user : PropTypes.object,
    onClick : PropTypes.func,
};

function UserFormEdit(props) {

    const { onClick, user } = props;
    const [ hierarchiesSel, setHierarchiesSel ] = useState([]);
    const [ typeAcces, setTypeAcces ] = useState(user.typeAcces || null);
    const [ profile, setProfile ] = useState(user.profile || null);

    const ITEMS_PROFILES = [
        { label : 'Super administrateur', value : PROFILES_CODES.SUPER_ADM, show : isSuperAdmin() },
        { label : 'Administrateur', value : PROFILES_CODES.ADMINISTRATOR, show : true },
        { label : 'Utilisateur', value : PROFILES_CODES.UTILISATEUR, show : true },
    ];

    const handleChange = ({ target : { name, value}}) => {
        if (name === 'typeAcces')   setTypeAcces(value);
        if (name === 'profile')   setProfile(value);
    };

    const handleEdit = () => {
        if (!onClick)  return;
        onClick(user, { perimetreAccessCode : hierarchiesSel.map(hierarchieSel => hierarchieSel?.code).join(','), typeAcces, profile });
    };

    return (
        <Box sx={{marginTop : 2, fontWeight : 'bold', fontSize : '20px'}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth margin="dense">
                                <InputLabel id="type-acces-label">{'Type d\'accès'}</InputLabel>
                                <Select labelId="type-acces-label" id="type-acces" name="typeAcces" fullWidth variant="outlined" value={typeAcces} label="Type d'accès" onChange={handleChange} size="small" defaultValue="">
                                    { Object.values(USER_TYPE_ACCESS).map((item, index) => (
                                        <MenuItem key={index} value={item.code}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <UserAccess onSelect={setHierarchiesSel} defaultValues={user?.hierarchieDtos} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth margin="dense">
                                <InputLabel id="profile-label">Profil</InputLabel>
                                <Select labelId="profile-label" id="profile" name="profile" fullWidth variant="outlined" value={profile} label="Profil" onChange={handleChange} size="small" defaultValue="">
                                    { ITEMS_PROFILES.filter(p => p.show).map((item, index) => (
                                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
                <Box display="none">
                    <Button variant="contained" color="primary" type="button" id="form-submit" onClick={handleEdit}> Mettre à jour </Button>
                </Box>
            </Grid>
        </Box>
    );
}

export default UserFormEdit;