import React from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { Redirect } from 'react-router';
import OktaSignInWidget from './OktaSignInWidget';

Login.propTypes = {
    config : PropTypes.object,
};

function Login({ config }) {
    const { oktaAuth, authState } = useOktaAuth();

    const onSuccess = (tokens) => {
      oktaAuth.handleLoginRedirect(tokens);
    };
  
    const onError = (err) => {
      console.log('error logging in', err);
    };
  
    if (!authState) return null;
  
    return (authState.isAuthenticated
       ? <Redirect to={{ pathname: '/' }} />
       : <OktaSignInWidget config={config} onSuccess={onSuccess} onError={onError} />
    );
}

export default Login;