import { Box, Button, Grid, Paper, TablePagination, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Loading from '../../../components/Loading';
import Popup from '../../../components/Popup';
import SkeletonList from '../../../components/Skeleton/List';
import jobApi from './../../../api/jobApi';
import JobAdd from './components/JobAdd';
import JobFilter from './components/JobFilter';
import JobList from './components/JobList';
import useUser from '../../../hook/useUser';
import { JOB_PERIOD } from '../../../constants';
import useNomJob from '../../../hook/useNomJob';
import { getMessageError } from '../../../util';

const ROW_OF_PAGE_DEFAULT = 10;

function Job() {

    const { enqueueSnackbar } = useSnackbar();
    useUser();

    const [ jobList, setJobList ] = useState([]);
    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});
    const location = useLocation();
    const history = useHistory();
    const nomJobList = useNomJob();

    const queryParam = useMemo(() => {
        const params = queryString.parse(location.search);
  
        return {
          ...params,
          page : Number.parseInt(params.page) || 0,
          size : Number.parseInt(params.size) || ROW_OF_PAGE_DEFAULT,
          status : params.status || [ JOB_PERIOD.A_TRAITER.code, JOB_PERIOD.EN_COURS.code, JOB_PERIOD.TRAITE.code ],
        };
    }, [location.search]);

    const [ rowsPerPage, setRowsPerPage ] = useState(queryParam.size || ROW_OF_PAGE_DEFAULT);
    const [ pagination, setPagination ] = useState({ page : 0, count : 1 });
    const [ loading, setLoading ] = useState(false);
    const [ filters, setFilters ] = useState(() => ({ 
        page : 0, size : queryParam.size || ROW_OF_PAGE_DEFAULT,
        begin : queryParam.begin ? (+queryParam.begin) : null,
        end : queryParam.end ? (+queryParam.end) : null,
        nomJob : queryParam.nomJob || ' ',
        status : queryParam.status || null,
    }));

    useEffect(() => {
        getListJob();
    }, [ queryParam ]);

    const getListJob = async () => {
        setLoading(true);
        try {
            const { page, size, ...params } = queryParam;
            if (params && params.begin)   params.begin = +params.begin;
            if (params && params.end)   params.end = +params.end;
            if (params.status && typeof params.status === 'string')    params.status = [params.status];
            const { items, totalItems, currentPage } = await jobApi.list({ page, size, params });
            setJobList(items);
            setPagination({ count : totalItems, page : currentPage });
        } catch (error) {
            const msg = getMessageError(error, 'Échec de l\'obtention la list des traitement, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilter) => {
        setFilters({...filters, ...newFilter });
    };

    const pushNewParams = newfilters => {
        history.push({
            pathname : history.location.pathname,
            search : queryString.stringify(newfilters),
        });
    };

    const handlePageChange = (e, page) => {
        const newfilters = {...queryParam, page };
        pushNewParams(newfilters);
    };

    const handleClick = () => {
        const newfilters = {...queryParam, page : 0, ...filters };
        pushNewParams(newfilters);
    };

    const handleChangeRowsPerPage = (event) => {
        const size = parseInt(event.target.value, 10);
        const newfilters = {...queryParam, size, page : 0 };
        pushNewParams(newfilters);
        setRowsPerPage(size);
    };

    return (<>
        { loading && <Loading/> }
        <Box>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Box sx={{marginTop : 5, fontWeight : 'bold', fontSize : '20px'}}>
                        <Paper elevation={1} sx={{padding : 2}}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography component="span" variant="h6" sx={{fontWeight: 'bold', color : 'paragraph.main'}}>{'Gestion des traitements'}</Typography>
                                <JobAdd callback={getListJob} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} />
                            </Box>
                            <JobFilter filters={filters} onChange={handleFilterChange} nomJobList={nomJobList} />
                            <Box display="flex" justifyContent="flex-end" sx={{marginRight : 2, marginTop : 1}}>
                                <Button onClick={handleClick} sx={{bgcolor: 'button.main', textTransform: 'none', color: 'buttonText.main', fontWeight : 'bold', '&:hover' : {bgcolor: 'button.main'}}} variant="contained" color="primary" size="small">
                                    Recherche
                                </Button>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <Paper elevation={1} sx={{padding : 2}}>
                        {loading 
                        ? <SkeletonList /> 
                        : <>
                            <JobList data={jobList} callback={getListJob} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} />
                            <Box display="flex" flexFlow="row nowrap" justifyContent="center" sx={{marginTop : 2.5, paddingBottom : 1}} >
                                <TablePagination 
                                    rowsPerPageOptions={[3, 10, 20, 50]}
                                    component="div"
                                    count={pagination.count}
                                    rowsPerPage={rowsPerPage}
                                    page={pagination.page}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage="Nombre de ligne sur page"
                                />
                            </Box>
                        </>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        <Popup open={openPopup} {...paramPopup} />
    </>);
}

export default Job;