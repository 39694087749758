import BlockIcon from '@mui/icons-material/Block';
import EditIcon from '@mui/icons-material/Edit';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import MenuMore from '../../../../components/MenuMore';
import { PROFILES_CODES, PROFILES_LABELS, USER_TYPE_ACCESS } from '../../../../constants';
import { getUserInfo, isAdmin } from '../../../../util';

UserItem.propTypes = {
    user : PropTypes.object,
    onEdit : PropTypes.func,
    onChange : PropTypes.func,
};

function UserItem(props) {

    const { user, onChange, onEdit } = props;
    const { palette : { button, tertiary } } = useTheme();

    const handleChange = (values, action) => {
        if (!onChange)   return;
        onChange(user, values, action);
    };

    const handleEdit = () => {
        if (!onEdit)   return;
        onEdit(user);
    };

    const me = getUserInfo();
    const isMe = me.identifiant === user.identifiant;

    const OPTIONS = [
        { title : 'Modifier', onClick : handleEdit, icon : EditIcon, color: button.main, show : true },
        { title : 'Activer', onClick : () => handleChange({active : true}, 'ACTIVER'), icon : LockOpenIcon, color: button.main, show : !user.active },
        { title : 'Désactiver', onClick : () => handleChange({active : false}, 'DESACTIVER'), icon : BlockIcon, color: tertiary.main, show : user.active },
    ];

    return (
        <TableRow hover>
            <TableCell>{user.nom}</TableCell>
            <TableCell>{user.prenom}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.identifiant}</TableCell>
            <TableCell>{PROFILES_LABELS[user.profile]}</TableCell>
            <TableCell>{user.typeAcces ? USER_TYPE_ACCESS[user.typeAcces]?.label : ''}</TableCell>
            <TableCell>{user?.hierarchieDtos.map(hierarchieSel => hierarchieSel?.libelle).join(', ')}</TableCell>
            <TableCell>{user.active ? 'Activé' : 'Désactivé'}</TableCell>
            <TableCell align="center">
                {(!isMe && !(isAdmin() && user.profile === PROFILES_CODES.SUPER_ADM)) && <MenuMore options={OPTIONS} />}
            </TableCell>
        </TableRow>
    );
}

export default UserItem;