import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import InputField from '../../../../components/form-controls/InputField';
import TextareaField from '../../../../components/form-controls/TextareaField';

ParamEditForm.propTypes = {
    param : PropTypes.object,
    onSubmit : PropTypes.func,
};

function ParamEditForm(props) {

    const { param, onSubmit } = props;

    const schema = yup.object().shape({
        value: yup.mixed().nullable(),
        description: yup.string().nullable(),
        unite: yup.string().nullable(),
    });

    const form = useForm({
        defaultValues: {
            value : param.value,
            description: param.description,
            unite: param.unite,
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        if (!onSubmit)  return;
        onSubmit(param.id, values);
    };

    return (
        <Box sx={{marginTop : 2, fontWeight : 'bold', fontSize : '20px'}}>
            <form onSubmit={form.handleSubmit(handleSubmit)} sx={{margin : 1}} id="form-modify" >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField label="Code de paramètre" variant="outlined" size="small" fullWidth disabled value={param.code}  />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField name="value" label="Valeur de paramètre" form={form} variant="outlined"/>
                            </Grid>
                            <Grid item xs={12}>
                                <InputField name="unite" label="Unité de paramètre" form={form} variant="outlined"/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextareaField name="description" label="Description de paramètre" form={form} variant="outlined"/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display="none">
                        <Button variant="contained" color="primary" type="submit" id="form-submit"> Enregistrer </Button>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}

export default ParamEditForm;