import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/job';

const jobApi = {
    async list(params){
        const url = `${BASE_URL}/list`;
        return axiosClient.post(url, params);
    },
    async delete(id){
        const url = `${BASE_URL}/${id}`;
        return axiosClient.delete(url);
    },
    async new(params){
        const url = `${BASE_URL}/new`;
        return axiosClient.post(url, params);
    },
    async listJob(periodUuid){
        const url = `${BASE_URL}/jobAvailable/${periodUuid}`;
        return axiosClient.get(url);
    },
    async allJob(){
        const url = `${BASE_URL}/allNomJob`;
        return axiosClient.get(url);
    },
};

export default jobApi;