import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

Popup.propTypes = {
    title : PropTypes.string,
    content : PropTypes.any,
    form : PropTypes.any,
    buttons : PropTypes.array,
    open : PropTypes.bool,
};

function Popup(props) {

    const { title, content, form, buttons, open } = props;

    return (
        <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                { content && <DialogContentText>{content}</DialogContentText> }
                { form && <>{form}</> }
            </DialogContent>
            <DialogActions>
                {
                    buttons && buttons.map((item, index) => (
                        <Button 
                            key={index} 
                            onClick={item.onClick} 
                            variant="contained"
                            size="small"
                            sx={{
                                textTransform : 'none', 
                                bgcolor : `${item.color}`, 
                                color : 'buttonText.main',
                                fontWeight : 'bold',
                                '&:hover' : {
                                    bgcolor : `${item.color}`,
                                    boxShadow : '0 5px 15px -10px rgba(31, 18, 53, 0.6)',
                                }
                            }} 
                        >
                            {item.label}
                        </Button>
                    ))
                }
            </DialogActions>
        </Dialog>
    );
}

export default Popup;