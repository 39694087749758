import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/kpi';

const kpiApi = {
    async list(params){
        const url = `${BASE_URL}/list`;
        return axiosClient.post(url, params);
    },
    async edit(id, params){
        const url = `${BASE_URL}/edit/${id}`;
        return axiosClient.post(url, params);
    },
    async listPrevious(params){
        const url = `${BASE_URL}/listPrevious`;
        return axiosClient.post(url, params);
    },
    async listAllAjust(periodUuid){
        const url = `${BASE_URL}/listAllAjust/${periodUuid}`;
        return axiosClient.get(url);
    },
};

export default kpiApi;