import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import metierApi from '../../../../api/metierApi';
import { getMessageError } from '../../../../util';

MetierFilter.propTypes = {
    filters : PropTypes.object,
    updateFilter : PropTypes.func,
    hasError : PropTypes.bool,
    forceReset : PropTypes.bool,
};

function MetierFilter(props) {

    const { filters, updateFilter, hasError = false, forceReset } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [ listMetier, setListMetier ] = useState(null);
    const [ itemSelected, setItemSelected ] = useState('');

    useEffect(() => {
        if (forceReset){
            setItemSelected('');
        }  
    }, [ forceReset ]);

    useEffect(() => {
        setItemSelected('');

        (async () => {
            if (filters.periodUuid){
                try {
                    const lst = await metierApi.listWithPeriod(filters.periodUuid);
                    setListMetier(lst);
                } catch (error) {
                    const msg = getMessageError(error, 'Échec de l\'obtention de liste des métiers, veuilez contacter avec le support');
                    enqueueSnackbar(msg, { variant: 'error' });
                } 
            }
        })();
    }, [ filters.periodUuid ]);

    const handleChange = (e) => {
        setItemSelected(e.target.value);
        if (!updateFilter)  return;
        updateFilter({ metierCode : e.target.value, centreCout : null, siteCode : null });
    };

    return (
        <FormControl variant="outlined" fullWidth margin="dense"error={hasError}>
            <InputLabel shrink id="select-label-metier">Métier</InputLabel>
                <Select labelId="select-label-metier" id="select-metier" value={itemSelected} fullWidth variant="outlined" label="Métier" size="small" onChange={handleChange}>
                    { listMetier
                    ? listMetier.map((item, index) => <MenuItem key={index} value={item.code}>{item.libelle}</MenuItem>) 
                    : <MenuItem value={''}>Aucun métier</MenuItem>
                    }
                </Select>
            { hasError && <FormHelperText>Sélection obligatoire</FormHelperText> }
        </FormControl>
    );
}

export default MetierFilter;