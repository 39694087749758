import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import kpiApi from '../../api/kpiApi';
import Loading from '../../components/Loading';
import useUser from '../../hook/useUser';
import { getMessageError } from '../../util';
import SkeletonList from './../../components/Skeleton/List';
import Filter from './Filter';
import Kpi from './Kpi';

function Home() {
    const { enqueueSnackbar } = useSnackbar();
    const [ kpis, setKpis ] = useState([]);
    const [ kpiShows, setKpiShows ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ filters, setFilters ] = useState(() => ({}));
    const [ checkError, setCheckError ] = useState({});
    const [ periodShow, setPeriodShow ] = useState(null);
    const [ periodPrevious, setPeriodPrevious ] = useState(null);
    const [ searchKey, setSearchKey ] = useState(null);
    const [ forceReset, setForceReset ] = useState(true);

    const location = useLocation();
    const history = useHistory();
    useUser();

    const queryParam = useMemo(() => {
        const params = queryString.parse(location.search);
        return { ...params };
    }, [ location.search ]);

    const handleFilterChange = (newFilter) => {
        setForceReset(false);
        setFilters({...filters, ...newFilter });
    };

    const pushNewParams = newfilters => {
        history.push({
            pathname : history.location.pathname,
            search : queryString.stringify(newfilters),
        });
    };

    const handleClick = () => {
        const newfilters = {...queryParam, centreCout : queryParam.centreCout ? JSON.parse(queryParam.centreCout) : null, ...filters };
        const fils = ['periodUuid', 'metierCode', 'societeCode', 'centreCout', 'siteCode'];
        const checks = fils.reduce((curr, f) => ({ ...curr, [f] : !newfilters[f] }), {});

        setCheckError(checks);
        if (fils.some(f => checks[f]))  return false;

        pushNewParams({ ...newfilters, centreCout : JSON.stringify(newfilters.centreCout)});
    };

    useEffect(() => {
        if (checkRequired(queryParam))  getListKpi();
    }, [ queryParam ]);

    const checkRequired = (objFilter) => ['periodUuid', 'metierCode', 'societeCode', 'centreCout', 'siteCode'].every(s => objFilter[s]);

    const handleChangeAjustement = (kpiId, newValue) => {
        const updateAjust = (lst, updateFunc) => {
            const newKpis = [...lst];
            const index = newKpis.findIndex(k => k.id === kpiId);
            if (index >= 0) newKpis[index].kpiDetail.valeurAjuste = +newValue;
            updateFunc(newKpis);
        };
        updateAjust(kpis, setKpis);
        updateAjust(kpiShows, setKpiShows);
    };

    const getListKpi = async () => {
        setLoading(true);
        try {
            const { periodUuid, metierCode, societeCode, siteCode, croissanceExterneCode, centreCout } = queryParam;
            const params = { periodUuid, metierCode, societeCode, siteCode, croissanceExterneCode, centreCoutCode : JSON.parse(centreCout).code  };
            const { kpiList, periodCurrent, periodPrevious : pPrevious } = await kpiApi.list(params);
            const lstUpdate = initialKpis(kpiList);
            syncForShowTable(lstUpdate);
            setPeriodShow(periodCurrent);
            setPeriodPrevious(pPrevious ? pPrevious : { libelle : 'Pas mis à jour'});
            setSearchKey(null);
        } catch (error) {
            const msg = getMessageError(error, 'Échec de récupération la liste de KPIs, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const initialKpis = (list) => {
        return list.map(item => {
            if (!item.kpiDetail) item.kpiDetail = {};
            if (!item.kpiDetail.valeurAjuste) item.kpiDetail.valeurAjuste = 0;
            item.kpiDetail.ajustementInit = item.kpiDetail.valeurAjuste || 0;
            return item;
        });
    };

    const handleUppdateAllKpi = () => {
        const kpiChanges = kpis.filter(kpi => kpi.kpiDetail.valeurAjuste !== kpi.kpiDetail.ajustementInit) || [];
        if (!kpiChanges.length) return;

        saveAjustements(kpiChanges);
    };

    const handleResetAllKpi = () => {
        const kpisInitial = kpis.map(kpi => ({ ...kpi, kpiDetail : { ...kpi.kpiDetail, valeurAjuste : kpi.kpiDetail.ajustementInit}}));
        syncForShowTable(kpisInitial);
    };

    const saveAjustements = async (lstChanges) => {
        try {
            setLoading(true);
            const promises = lstChanges.map(kpi => {
                const { periodUuid, metierCode, societeCode, siteCode, croissanceExterneCode, centreCout } = queryParam;
                const params = { valeurAjuste : kpi.kpiDetail.valeurAjuste, periodUuid, metierCode, societeCode, siteCode, croissanceExterneCode, centreCoutCode : JSON.parse(centreCout).code, kpiDetailId : kpi.kpiDetail.id };
                return kpiApi.edit(kpi.id, params);
            });
    
            const updateds = await Promise.all(promises);
            const newKpis = [...kpis];
            updateds.forEach(kpi => {
                const index = newKpis.findIndex(k => k.id === kpi.id);
                if (index >= 0) newKpis[index] = kpi;
            });

            const newKpiInits = initialKpis(newKpis);
            syncForShowTable(newKpiInits);

            enqueueSnackbar('Mise à jour les KPIS avec succès', { variant: 'success' });
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue dans la Mise à jour les KPIS, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleSearchTable = ({ target : { value }}) => {
        setSearchKey(value);
        const newKpis = kpis.filter(k => k.libelle.toLowerCase().includes(value.toLowerCase())) || [];
        setKpiShows(newKpis);
    };

    const syncForShowTable = (lst) => {
        setKpis(lst);
        setKpiShows(JSON.parse(JSON.stringify(lst)));
    };

    const handleInit = () => {
        setForceReset(true);
        setFilters({ periodUuid : filters.periodUuid, metierCode : null, societeCode : null, centreCout : null, siteCode : null, croissanceExterneCode : null });
    };

    return (<>
        { loading && <Loading/> }
        <Box sx={{padding : 5}}>
            <Box>
                <Paper elevation={1} sx={{padding : 2}}>
                    <Typography component="span" variant="h6" sx={{fontWeight: 'bold', color : 'paragraph.main'}}>Critères de sélection</Typography>
                    <Filter filters={filters} onChange={handleFilterChange} checkError={checkError} forceReset={forceReset} />
                    <Box display="flex" justifyContent="flex-end" sx={{marginRight : 0, marginTop : 2}}>
                        <Button onClick={handleClick} sx={{bgcolor: 'button.main', textTransform: 'none', color: 'buttonText.main', fontWeight : 'bold', '&:hover' : {bgcolor: 'button.main'}, marginRight : 1}} variant="contained" color="primary" size="small">
                            Recherche
                        </Button>
                        <Button onClick={handleInit} sx={{bgcolor: 'button.default', textTransform: 'none', color: 'buttonText.main', fontWeight : 'bold', '&:hover' : {bgcolor: 'button.default'}}} variant="contained" color="primary" size="small">
                            Réinitialiser
                        </Button>
                    </Box>
                </Paper>
            </Box>
            <Box sx={{marginTop : 5}}>
                <Paper elevation={1} sx={{padding : 2}}>
                    <Box sx={{display : 'flex', justifyContent : 'space-between'}}>
                        <Typography component="span" variant="h6" sx={{fontWeight: 'bold', color : 'paragraph.main'}}>Détail des KPIs</Typography>
                        <TextField label="Filtrer par libellé" variant="outlined" size="small" value={searchKey} onChange={handleSearchTable} />
                    </Box>
                    {loading
                    ? <SkeletonList />
                    : <Kpi data={kpiShows} onAllUpdate={handleUppdateAllKpi} onChange={handleChangeAjustement} periodShow={periodShow} periodPrevious={periodPrevious} onReset={handleResetAllKpi} filters={filters} />
                    }
                </Paper>
            </Box>
        </Box>
    </>);
}

export default Home;
