import { Menu, MenuItem, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import paramApi from '../../../../api/paramApi';
import TreeSelect from '../../../../components/TreeSelect';
import { getMessageError } from '../../../../util';

CentreCoutFilter.propTypes = {
    filters : PropTypes.object,
    updateFilter : PropTypes.func,
    hasError : PropTypes.bool,
    isDisabled : PropTypes.bool,
    forceReset : PropTypes.bool,
};

function CentreCoutFilter(props) {

    const { filters, updateFilter, hasError = false, isDisabled = true, forceReset } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [ itemSelected, setItemSelected ] = useState(' ');
    const [ data, setData ] = useState(null);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ searchKey, setSearchKey ] = useState('');

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (forceReset)  setItemSelected(' ');
    }, [ forceReset ]);

    useEffect(() => {
        setItemSelected(' ');
    }, [ filters.periodUuid, filters.societeCode, filters.metierCode ]);

    useEffect(() => {
        (async () => {
            if (filters.periodUuid && filters.societeCode && filters.metierCode){
                try {
                    const lst = await paramApi.listCentreCout(filters.periodUuid, filters.societeCode, filters.metierCode, searchKey);
                    setData({ childs : lst });
                    if (lst.length === 1){
                     setItemSelected(lst[0].libelle);
                     updateFilter({ centreCout : lst[0]});
                    }
                } catch (error) {
                    setData({ childs : [] });
                    const msg = getMessageError(error, 'Échec de l\'obtention de liste des centres coût, veuilez contacter avec le support');
                    enqueueSnackbar(msg, { variant: 'error' });
                } 
            }
        })();
    }, [ filters.periodUuid, filters.societeCode, filters.metierCode, searchKey ]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelect = (item) => {
        setItemSelected(item.libelle);
        if (!updateFilter)  return;
        updateFilter({ centreCout : item, siteCode : null });
        setAnchorEl(null);
    };

    const handleSeach = (searchKey) => {
        setSearchKey(searchKey);
    };
 
    return (<>
        <TextField
            id="basic-button-centre-cout"
            aria-controls="basic-menu-centre-cout"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            label="Centre de coût" 
            variant="outlined" 
            size="small" 
            fullWidth 
            value={itemSelected}
           
            helperText={hasError ? 'Sélection obligatoire' : '' }
            error={hasError}
            disabled={isDisabled}
        />
            <Menu
                id="basic-menu-centre-cout"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button-centre-cout' }}
                PaperProps={{ style: { width: '60ch' } }}
            >
                <MenuItem sx={{padding : 0}}>
                    { data && <TreeSelect data={data} onSelect={handleSelect} onSearch={handleSeach} name="centre-cout" selectLabel="Sélectionner un centre de coût" /> }
                </MenuItem>
            </Menu>
    </>);
}

export default CentreCoutFilter;