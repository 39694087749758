
const TRANSLATE_DATE = {
    1 : 'Janvier', 2 : 'Février', 3 : 'Mars', 4 : 'Avril', 5 : 'Mai', 6 : 'Juin', 7 : 'Juillet', 8 : 'Août', 9 : 'Septembre', 10 : 'Octobre', 11 : 'Novembre', 12 : 'Décembre'
};

const formatDate = (date) => {
    if (!date)   return '';
    const newDate = new Date(date);

    return new Intl.DateTimeFormat('fr-FR').format(newDate);
};

const formatDateTime = (date) => {
    if (!date)   return '';
    const newDate = new Date(date);

    return new Intl.DateTimeFormat('fr-FR', { dateStyle: 'short', timeStyle: 'medium' }).format(newDate);
};

const translateDate = date => {
    if (!date.includes('-'))    return date;
    const [ month, year ] = date.split('-');
    const monthTrans = TRANSLATE_DATE[+month];

    return `${monthTrans} ${year}`;
};

const getCurrentYear = () => new Date().getFullYear();

export { formatDate, getCurrentYear, translateDate, formatDateTime };