import { Menu, MenuItem, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import paramApi from '../../../../api/paramApi';
import TreeSelect from '../../../../components/TreeSelect';
import { ERROR_CODE_USER } from '../../../../constants';
import { getMessageError } from '../../../../util';

SocieteFilter.propTypes = {
    filters : PropTypes.object,
    updateFilter : PropTypes.func,
    hasError : PropTypes.bool,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
    forceReset : PropTypes.bool,
};

function SocieteFilter(props) {

    const { filters, updateFilter, hasError = false, setOpenPopup, setParamPopup, forceReset } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [ itemSelected, setItemSelected ] = useState(' ');
    const [ data, setData ] = useState(null);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ searchKey, setSearchKey ] = useState('');

    const open = Boolean(anchorEl);

    useEffect(() => {
        if (forceReset){
            setItemSelected(' ');
        }  
    }, [ forceReset ]);
    
    useEffect(() => {
        setItemSelected(' ');
    }, [ filters.periodUuid ]);

    const handleErrorPerimetAcces = (content) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Information',
            content,
            buttons : [
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    useEffect(() => {
        (async () => {
            if (filters.periodUuid){
                try {
                    const lst = await paramApi.listSociete(filters.periodUuid, searchKey);
                    setData({ childs : lst });
                } catch (error) {
                    setData({ childs : [] });
                    if (error.code && error.code === ERROR_CODE_USER.HAVE_NOT_PERIMET_ACCESS.code){
                        handleErrorPerimetAcces(ERROR_CODE_USER.HAVE_NOT_PERIMET_ACCESS.msg);
                    } else {
                        const msg = getMessageError(error, 'Échec de l\'obtention de liste des sociétés, veuilez contacter avec le support');
                        enqueueSnackbar(msg, { variant: 'error' });
                    }
                } 
            }
        })();
    }, [ filters.periodUuid, searchKey ]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelect = (item) => {
        setItemSelected(item.libelle);
        if (!updateFilter)  return;
        updateFilter({ societeCode : item.codeSiFinance, centreCout : null, siteCode : null });
        setAnchorEl(null);
    };

    const handleSeach = (searchKey) => {
        setSearchKey(searchKey);
    };

    return (<>
        <TextField
            id="basic-button-societe"
            aria-controls="basic-menu-centre-cout"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            label="Société" 
            variant="outlined" 
            size="small" 
            fullWidth 
            value={itemSelected}

            helperText={hasError ? 'Sélection obligatoire' : '' }
            error={hasError}
        />
        <Menu
            id="basic-menu-centre-cout"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{ 'aria-labelledby': 'basic-button-centre-cout' }}
            PaperProps={{ style: { width: '60ch' } }}
        >
            <MenuItem sx={{padding : 0}}>
                { data && <TreeSelect data={data} onSelect={handleSelect} onSearch={handleSeach} name="societe" selectLabel="Sélectionner une société" /> }
            </MenuItem>
        </Menu>
    </>);
}

export default SocieteFilter;