import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Divider, IconButton, List, ListItem, ListItemText, ListSubheader, TextField, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useDebounce from '../../hook/useDebounce';

TreeSelect.propTypes = {
    data : PropTypes.object,
    onSelect : PropTypes.func,
    onGetChilds : PropTypes.func,
    onBack : PropTypes.func,
    onSearch : PropTypes.func,
    name : PropTypes.string,
    selectLabel : PropTypes.string,
};

function TreeSelect(props) {

    const { data = {}, onSelect, onGetChilds, onBack, onSearch, name = '', selectLabel = 'Choisir un' } = props;
    const { childs = [] } = data;

    const [ inputValue, setInputValue ] = useState('');
    const debouncedSearchTerm = useDebounce(inputValue, 500);

    const handleChange = (e) => {
        setInputValue(e.target.value);
    };

    useEffect(() => {
        if (onSearch)   onSearch(debouncedSearchTerm);
    }, [ debouncedSearchTerm ]);

    const handleSelect = (item) => {
        onSelect && onSelect(item);
    };

    const handleShowChild = (item) => {
        onGetChilds && onGetChilds(item);
    };

    const handleBack = () => {
        onBack && onBack(data);
    };

    return (<>
        <TextField type="hidden"/>
        <List sx={{ width: '100%', bgcolor : 'white' }} component="nav" aria-labelledby={`nested-list-subheader-${name}`}
        subheader={
            <ListSubheader component="div" id={`nested-list-subheader-${name}`}>
                { data.uuid
                    ? <Box sx={{display:'flex', alignItems: 'center', marginY : 1}}><ArrowBackIcon onClick={handleBack} sx={{marginRight : 1}} /> {data.libelleHierarchie}</Box>
                    : `${selectLabel}`
                }
                <Box>
                    <TextField variant="outlined" size="small" name={name} fullWidth placeholder="Recherche" onChange={handleChange} />
                </Box>
            </ListSubheader>
        }>
            {
                childs.map((item, index) => (<>
                    <ListItem key={index} sx={{'&:hover' : { bgcolor : 'rgba(0, 0, 0, 0.04)'}}}>
                        <ListItemText primary={<Typography variant="subtitle2" sx={{color : 'paragraph.main'}} onClick={() => handleSelect(item)}>{item.libelle}</Typography>}  />
                        {item.childs && <IconButton size='small' sx={{padding : 0}} onClick={() => handleShowChild(item)}><ChevronRightIcon /></IconButton>}
                    </ListItem>
                    <Divider variant="inset" component="li" sx={{marginX : 2}} />
                </>))
            }
        </List>
        </>);
}

export default TreeSelect;