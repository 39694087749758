import { useSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import userApi from '../../../../api/userApi';
// import exportXLSX from '../../../../components/Export/exportXLSX';
import exportXlsPopulate from '../../../../components/Export/exportXlsPopulate';
import Loading from '../../../../components/Loading';
import MenuMore from '../../../../components/MenuMore';
import { PROFILES_LABELS, USER_TYPE_ACCESS } from '../../../../constants';
import { formatDateTime, getMessageError } from '../../../../util';

const headers = [
    { label: 'Identifiant', key: 'identifiant' },
    { label: 'Profil', key: 'profile' },
    { label: 'Nom', key: 'nom' },
    { label: 'Prénom', key: 'prenom' },
    { label: 'Email', key: 'email' },
    { label: 'Dernière connexion', key: 'lastLogin' },
    { label: 'Date création', key: 'createDate' },
    { label: 'Créé par', key: 'createBy' },
    { label: 'Date de modification', key: 'modifyDate' },
    { label: 'Modifié par', key: 'modifyBy' },
    { label: 'Active', key: 'active' },
    { label: 'Périmère d\'accès code', key: 'perimetreAccessCode' },
    { label: 'Type d\'accès', key: 'typeAcces' },
];

function ExportExcel() {

    const csvLink = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const [ users, setUsers ] = useState([]);
    const [ loading, setLoading ] = useState(false);

    const getListAllUser = async () => {
        setLoading(true);
        try {
            const res = await userApi.listAll();
            const dataUsers = res.map(transfertLabel);
            setUsers(dataUsers);
            return dataUsers;
        } catch (error) {
            const msg = getMessageError(error, 'Échec de l\'obtention de l\'utilisateur de la liste, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const transfertLabel = (user) => {
        return {
            ...user,
            createDate : formatDateTime(user.createDate), 
            modifyDate : formatDateTime(user.modifyDate),
            lastLogin : formatDateTime(user.lastLogin),
            profile : PROFILES_LABELS[user.profile],
            typeAcces : user.typeAcces ? USER_TYPE_ACCESS[user.typeAcces]?.label : '',
            active : user.active ? 'Oui' : 'Non',
        };
    };

    const handleExportXlsx = async () => {
        const dataUsers = await getListAllUser();
        exportXlsPopulate(dataUsers, 'utilisateur_infomation_xlsx', headers);
    };

    const handleExportCsv = async () => {
        await getListAllUser();
        csvLink.current.link.click();
    };

    const OPTIONS = [
        { title : 'Export XLSX', onClick : () => handleExportXlsx(), show : true },
        { title : 'Export CSV', onClick : () => handleExportCsv(), show : true },
    ];
    
    return (
        <>
            { loading && <Loading/> }
            <MenuMore options={OPTIONS} />
            <CSVLink style={{display : 'none'}} headers={headers} data={users} filename="utilisateur_infomations_csv.csv" ref={csvLink} />
        </>
    );
}

export default ExportExcel;