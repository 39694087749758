import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import GroupIcon from '@mui/icons-material/Group';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import BorderColor from '@mui/icons-material/BorderColor';
import TimerIcon from '@mui/icons-material/Timer';
import { Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { isAdminCommon, isSuperAdmin } from '../../util';
import useUser from './../../hook/useUser';

const useStyles = makeStyles((theme) => ({
  toolbar: theme.mixins.toolbar,
}));

function DrawerItem() {

  useUser();

  const isSelected = (route) => {
    const match = useRouteMatch(route);
    return !!match && match.isExact;
  };

  const ADMINISTRATION_ITEMS = [
    { label : 'Utilisateurs', icon : <GroupIcon fontSize="small" />, path : '/administration/user', active : true, isMatch : isSelected('/administration/user') },
    { label : 'Métiers analytiques', icon : <AlternateEmailIcon fontSize="small" />, path : '/administration/metier', active : true, isMatch : isSelected('/administration/metier')  },
    { label : 'Périodes', icon : <TimerIcon fontSize="small" />, path : '/administration/period', active : true, isMatch : isSelected('/administration/period')  },
    { label : 'Traitements', icon : <HighlightAltIcon fontSize="small" />, path : '/administration/job', active : true, isMatch : isSelected('/administration/job')  },
    { label : 'Configurations', icon : <PermDataSettingIcon fontSize="small" />, path : '/administration/params', active : isSuperAdmin(), isMatch : isSelected('/administration/params')  },
    { label : 'KPI paramétrage', icon : <BorderColor fontSize="small" />, path : '/administration/kpi-params', active : isAdminCommon(), isMatch : isSelected('/administration/kpi')  },
  ];

  const [ open, setOpen ] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  const classes = useStyles();

  return (
    <div>
      <div className={classes.toolbar} />
      <Divider />
        <List sx={{bgcolor : 'background.main'}}>
          <ListItem selected={isSelected('/')} button={true} key={'Home'} component={Link} to="/">
            <ListItemIcon><HomeOutlinedIcon fontSize="small" /></ListItemIcon>
            <ListItemText primary={ <Typography variant="body2">Accueil</Typography> } />
          </ListItem>
          <ListItem selected={isSelected('/export')} button={true} key={'Export'} component={Link} to="/export">
            <ListItemIcon><ImportExportIcon fontSize="small" /></ListItemIcon>
            <ListItemText primary={ <Typography variant="body2">Export</Typography> } />
          </ListItem>
        </List>
        {
          isAdminCommon() && 
          <>
            <Divider />
            <List sx={{bgcolor : 'background.main'}}>
              <ListItemButton button="true" key={'Administration'} component={'div'} onClick={handleClick}>
                <ListItemIcon><SettingsOutlinedIcon fontSize="small" /></ListItemIcon>
                <ListItemText primary={<Typography variant="body2">Administration</Typography> } />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {
                    ADMINISTRATION_ITEMS.filter(item => item.active).map((item, index) => (
                      <ListItemButton key={index} sx={{ pl: 4 }} component={Link} to={item.path} selected={item.isMatch}>
                        <ListItemIcon> {item.icon}</ListItemIcon>
                        <ListItemText primary={<Typography variant="subtitle2"> {item.label} </Typography>} />
                      </ListItemButton>
                    ))
                  }
                </List>
              </Collapse>
            </List>
          </>
        }
      </div>
  );
}

export default DrawerItem;
