import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import paramApi from '../../../../api/paramApi';
import Loading from '../../../../components/Loading';
import { getMessageError, translateDate } from '../../../../util';
import ParamEditForm from './ParamEditForm';
import ParamItem from './ParamItem';

ParamList.propTypes = {
    data : PropTypes.array,
    callback : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
};

function ParamList(props) {

    const { data = [], callback, setOpenPopup, setParamPopup } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false);

    const handleDelParam = async (param) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Confirmation',
            content : <>{'Vous êtes sûr de supprimer le paramètre '} <b>{param.code}</b> ? </>,
            buttons : [
                {label : 'Supprimer', color : 'button.main', onClick : () => delParam(param)},
                {label : 'Annuler', color : 'inherit', onClick : resetState},
            ]
        });
    };

    const delParam = async (param) => {
        setLoading(true);
        try {
            await paramApi.delParams(param.id);
            enqueueSnackbar('Supprimer la paramètrage avec succès.', { variant: 'success' });
            callback && callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en supprimant cette paramètrage, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            resetState();
        }
    };

    const resetState = () => {
        setLoading(false);
        setOpenPopup(false);
        setParamPopup({});
    };

    const handleEditParam = async (param) => {
        setOpenPopup(true);
        setParamPopup({
            title : <>{'Modification la paramètrage '} <b>{translateDate(param.code)}</b></>,
            form : <ParamEditForm param={param} onSubmit={editParam} />,
            buttons : [
                {label : <label htmlFor='form-submit'>Mettre à jour</label>, color : 'button.main' },
                {label : 'Annuler', color : 'inherit', onClick : resetState},
            ]
        });
    };

    const editParam = async (id, values) => {
        setLoading(true);
        try {
            await paramApi.editParams(id, values);
            enqueueSnackbar('Modifier la période avec succès.', { variant: 'success' });
            callback && callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en modifiant cette paramètrage, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            resetState();
        }
    };

    return (<>
        { loading && <Loading/> }
        <TableContainer component={Paper}>
            <Table sx={{minWidth : 650}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight : 'bold'}}>Code</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Valeur</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Unité</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Description</TableCell>
                        <TableCell align="center" sx={{fontWeight : 'bold'}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Boolean(data.length) && data.map((item, index) => (
                            <ParamItem key={index} param={item} onDelete={handleDelParam} onEdit={handleEditParam} />
                        ))
                    }
                    {
                        Boolean(!data.length) && (
                            <TableRow hover >
                                <TableCell colSpan={7} sx={{textAlign : 'center'}}>Aucune paramètrage trouvé</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>);
}

export default ParamList;