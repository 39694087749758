import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { TextField } from '@mui/material';

InputField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    size : PropTypes.string,
    variant : PropTypes.string,
    handleChange : PropTypes.func,
};

function InputField(props) {
    const { form, name, label, disabled, size = 'small', variant = 'outlined', handleChange } = props;
    const { formState: { errors } } = form;
    const hasError = errors[name];

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field: { onChange, onBlur, value, name } }) => (
                <TextField
                    margin="normal"
                    variant={variant}
                    fullWidth
                    label={label}
                    disabled={disabled}
                    error={!!hasError}
                    helperText={errors[name]?.message}
                    name={name}
                    value={value}
                    onChange={(e) => {
                        handleChange && handleChange(e.target.value);
                        onChange(e);
                    }}
                    onBlur={onBlur}
                    size={size}
                  
                    sx={{marginTop : 0}}
                />
            )}
        />
    );
}

export default InputField;