import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/home';

const homeApi = {
    async refManual(period){
        const url = `${BASE_URL}/referentiel/manuel/${period}`;
        return axiosClient.get(url, period);
    },
    async getVersion(){
        return axiosClient.get(BASE_URL);
    },
};

export default homeApi;