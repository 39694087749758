import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import InputField from '../../../../components/form-controls/InputField';
import TextareaField from '../../../../components/form-controls/TextareaField';

ParamAddForm.propTypes = {
    onSubmit : PropTypes.func,
};

function ParamAddForm(props) {
    const { onSubmit } = props;

    const schema = yup.object().shape({
        code: yup.string().required('Veuillez saisir un code'),
        value: yup.mixed().required('Veuillez saisir une valeur'),
        unite: yup.string().nullable(),
        description: yup.string().nullable(),
    });

    const form = useForm({
        defaultValues: {
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        if (!onSubmit)  return;
        onSubmit({ ...values });
    };

    return (
        <Box sx={{marginTop :2, fontWeight : 'bold', fontSize : '20px'}}>
            <form onSubmit={form.handleSubmit(handleSubmit)} sx={{margin : 1}} id="form-modify" >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <InputField name="code" label="Code de paramètrage" form={form} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField name="value" label="Valeur de paramètrage" form={form} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <InputField name="unite" label="Unité de paramètrage" form={form} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextareaField name="description" label="Description de paramètrage" form={form} variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display="none">
                        <Button variant="contained" color="primary" type="submit" id="form-submit"> Enregistrer </Button>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}

export default ParamAddForm;