import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import userApi from '../../../../api/userApi';
import InputField from '../../../../components/form-controls/InputField';
import SelectField from '../../../../components/form-controls/SelectField';
import { ERROR_CODE_USER } from '../../../../constants';
import { PROFILES_CODES, USER_TYPE_ACCESS } from '../../../../constants/storages-keys';
import useDebounce from '../../../../hook/useDebounce';
import { isSuperAdmin } from '../../../../util';
import UserAccess from './UserAccess';

UserFormAdd.propTypes = {
    user : PropTypes.object,
    onSubmit : PropTypes.func,
};

function UserFormAdd(props) {

    const { onSubmit } = props;
    const [ userFind, setUserFind ] = useState(null);
    const [ hierarchiesSel, setHierarchiesSel ] = useState([]);
    const [ inputValue, setInputValue ] = useState('');
    const debouncedSearchTerm = useDebounce(inputValue, 500);

    const handleChangeIdentifiant = (value) => {
        setInputValue(value);
    };

    const ITEMS_PROFILES = [
        { label : 'Super administrateur', value : PROFILES_CODES.SUPER_ADM, show : isSuperAdmin() },
        { label : 'Administrateur', value : PROFILES_CODES.ADMINISTRATOR, show : true },
        { label : 'Utilisateur', value : PROFILES_CODES.UTILISATEUR, show : true },
    ];

    useEffect(() => {
        (async () => {
          if (debouncedSearchTerm && debouncedSearchTerm.length){
            try {
              const res = await userApi.find(debouncedSearchTerm);
              setUserFind(res);
              form.clearErrors('identifiant');
            } catch (error) {
                const { code } = error;
                !!code && form.setError('identifiant', { type : 'manual', message : ERROR_CODE_USER[code].msg });
                setUserFind(null);
            }
          }
        })();
    }, [debouncedSearchTerm]);
    
    const schema = yup.object().shape({
        identifiant: yup.string().required('Veuillez saisir l\'identifiant'),
        profile: yup.string().required('Veuillez choisir le profile de cet utilisateur'),
        typeAcces: yup.string().required('Veuillez choisir le type d\'accès pour cet utilisateur'),
    });

    const form = useForm({
        defaultValues: {
            identifiant : '',
            profile : PROFILES_CODES.UTILISATEUR,
            typeAcces : USER_TYPE_ACCESS.LECTURE_SEUL.code,
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        if (!onSubmit)  return;
        onSubmit({ ...values, perimetreAccessCode : hierarchiesSel.map(hierarchieSel => hierarchieSel?.code).join(',') });
    };

    return (
        <Box sx={{marginTop : 2, fontWeight : 'bold', fontSize : '20px'}}>
            <form onSubmit={form.handleSubmit(handleSubmit)} sx={{margin : 1}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <InputField name="identifiant" label="Identifiant" form={form} variant="outlined" handleChange={handleChangeIdentifiant} />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectField name="profile" label="Profil" form={form} items={ITEMS_PROFILES.filter(p => p.show)} />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectField name="typeAcces" label="Type d'accès" form={form} items={Object.values(USER_TYPE_ACCESS)} />
                            </Grid>
                            <Grid item xs={12}>
                                <UserAccess onSelect={setHierarchiesSel} />
                            </Grid>
                            {
                                userFind && <>
                                <Grid item xs={12}>
                                    <TextField label="Nom" variant="outlined" size="small" fullWidth disabled value={userFind.nom} />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Prénom" variant="outlined" size="small" fullWidth disabled value={userFind.prenom}  />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField label="Email" variant="outlined" size="small" fullWidth disabled value={userFind.email}  />
                                </Grid>
                            </>
                            }
                        </Grid>
                    </Grid>
                    <Box display="none">
                        <Button variant="contained" color="primary" type="submit" id="form-submit"> Enregistrer </Button>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}



export default UserFormAdd;