import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import DesktopDate from '../../../../components/form-controls/DateField/DesktopDate';
import SelectField from '../../../../components/form-controls/SelectField';
import { PERIOD_STATUS } from '../../../../constants';
import { formatDate, translateDate } from '../../../../util';

PeriodEditForm.propTypes = {
    period : PropTypes.object,
    onSubmit : PropTypes.func,
};

function PeriodEditForm(props) {

    const { period, onSubmit } = props;

    const schema = yup.object().shape({
        dateOuverture: yup.date().nullable(),
        dateCloture: yup.date().nullable(),
        status: yup.string(),
    });

    const form = useForm({
        defaultValues: {
            status : period.status,
            dateOuverture: period.dateOuverture ? new Date(period.dateOuverture) : null,
            dateCloture: period.dateCloture ? new Date(period.dateCloture) : null,
        },
        resolver : yupResolver(schema),
    });

    const statusPeriodDisabled = [ PERIOD_STATUS.CREEE.code, PERIOD_STATUS.OUVERT.code ].includes(period.status);
    const isClosePeriod = period.status === PERIOD_STATUS.CLOTURE.code;
    const ITEMS_STATUS_PERIOD = isClosePeriod ? [ PERIOD_STATUS.OUVERT, PERIOD_STATUS.CLOTURE ] : Object.values(PERIOD_STATUS);

    const handleSubmit = (values) => {
        if (!onSubmit)  return;
        onSubmit(period.uuid, values);
    };

    return (
        <Box sx={{marginTop : 2, fontWeight : 'bold', fontSize : '20px'}}>
            <form onSubmit={form.handleSubmit(handleSubmit)} sx={{margin : 1}} id="form-modify" >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <DesktopDate name="dateOuverture" label="Date d'ouverture" form={form} variant="outlined" minDate={new Date(period.dateDebut)} />
                            </Grid>
                            <Grid item xs={12}>
                                <DesktopDate name="dateCloture" label="Date clôturé" form={form} variant="outlined" minDate={new Date(period.dateFin)} />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectField disabled={statusPeriodDisabled} name="status" label="Status" form={form} items={ITEMS_STATUS_PERIOD} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Libéllé" variant="outlined" size="small" fullWidth disabled value={translateDate(period.libelle)} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Date de début" variant="outlined" size="small" fullWidth disabled value={formatDate(period.dateDebut)}  />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Date de fin" variant="outlined" size="small" fullWidth disabled value={formatDate(period.dateFin)}  />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display="none">
                        <Button variant="contained" color="primary" type="submit" id="form-submit"> Enregistrer </Button>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}

export default PeriodEditForm;