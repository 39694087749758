import { Box, Grid, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import periodApi from '../../../api/periodApi';
import Loading from '../../../components/Loading';
import Popup from '../../../components/Popup';
import SkeletonList from '../../../components/Skeleton/List';
import useUser from '../../../hook/useUser';
import { getCurrentYear, getMessageError } from '../../../util';
import PeriodAdd from './components/PeriodAdd';
import PeriodFilter from './components/PeriodFilter';
import PeriodList from './components/PeriodList';

Period.propTypes = {
};

function Period() {

    const { enqueueSnackbar } = useSnackbar();
    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});
    const location = useLocation();
    const history = useHistory();
    const [ loading, setLoading ] = useState(false);
    const [ periodList, setPeriodList ] = useState([]);

    useUser();

    const queryParam = useMemo(() => {
        const params = queryString.parse(location.search);
        return { year : Number.parseInt(params.year) || getCurrentYear() };
    }, [location.search]);

    useEffect(() => {
        getListPeriod();
    }, [ queryParam ]);

    const getListPeriod = async () => {
        setLoading(true);
        try {
            const { year } = queryParam;
            const items = await periodApi.list(year);
            setPeriodList(items);
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue de récupération la liste de période, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (year) => {
        pushNewParams({...queryParam, year });
    };

    const pushNewParams = newfilters => {
        history.push({
            pathname : history.location.pathname,
            search : queryString.stringify(newfilters),
        });
    };

    const handleSortByStatus = (sortASC = true) => {
        const newList = periodList.sort((a, b) => sortASC ? a.status.localeCompare(b.status) : (-1) * a.status.localeCompare(b.status));
        setPeriodList(newList);
    };

    const handleSortByDateDebut = (sortASC = true) => {
        const newList = periodList.sort((a, b) => sortASC ? (a.dateDebut - b.dateDebut) : (-1) * (a.dateDebut - b.dateDebut));
        setPeriodList(newList);
    };

    return (<>
        { loading && <Loading/> }
        <Box>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Box sx={{marginTop : 5, fontWeight : 'bold', fontSize : '20px'}}>
                        <Paper elevation={1} sx={{padding : 2}}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography component="span" variant="h6" sx={{fontWeight: 'bold', color : 'paragraph.main'}}>{'Choisir une année'}</Typography>
                                <PeriodAdd callback={getListPeriod} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup}/>
                            </Box>
                            <PeriodFilter onChange={handleFilterChange} yearSearch={queryParam.year} />
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <Paper elevation={1} sx={{padding : 2}}>
                        {loading 
                        ? <SkeletonList /> 
                        : <PeriodList data={periodList} callback={getListPeriod} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} onSortStatus={handleSortByStatus} onSortDateDebut={handleSortByDateDebut} />
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        <Popup open={openPopup} {...paramPopup} />
    </>);
}

export default Period;