import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import userApi from '../api/userApi';
import { STORAGES_KEYS, PROFILES_CODES } from '../constants/storages-keys';
import { getMessageError } from '../util';

export default function(){
  const [ user, setUser ] = useState({ profile : PROFILES_CODES.UTILISATEUR});
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
        let user = JSON.parse(localStorage.getItem(STORAGES_KEYS.USER));
        if (!user){
          const res = await userApi.get();
          localStorage.setItem(STORAGES_KEYS.USER, JSON.stringify(res));
          user = res;
        }
        setUser(user);
      } catch (error) {
        const msg = getMessageError(error, 'Ne peut pas télécharger l\'information de l\'utilisateur, veuilez contacter avec le support');
        enqueueSnackbar(msg, { variant: 'error' });
      }
    })();
  }, []);

  return user;
}