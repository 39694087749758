import { Box, Grid, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import paramApi from '../../../api/paramApi';
import Loading from '../../../components/Loading';
import Popup from '../../../components/Popup';
import SkeletonList from '../../../components/Skeleton/List';
import useUser from '../../../hook/useUser';
import { getMessageError } from '../../../util';
import ParamAdd from './components/ParamAdd';
import ParamList from './components/ParamList';

Parametrage.propTypes = {
};

function Parametrage() {

    const { enqueueSnackbar } = useSnackbar();
    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});
    const [ loading, setLoading ] = useState(false);
    const [ params, setParams ] = useState([]);

    useUser();

    useEffect(() => {
        getListParams();
    }, []);

    const getListParams = async () => {
        setLoading(true);
        try {
            const items = await paramApi.listParams();
            setParams(items);
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue de récupération la liste de paramètrages, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    return (<>
        { loading && <Loading/> }
        <Box>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Box sx={{marginTop : 5, fontWeight : 'bold', fontSize : '20px'}}>
                        <Paper elevation={1} sx={{padding : 2}}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography component="span" variant="h6" sx={{fontWeight: 'bold', color : 'paragraph.main'}}>{'Paramètres'}</Typography>
                                <ParamAdd callback={getListParams} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup}/>
                            </Box>
                            <Box sx={{marginTop : 2}}>
                                {loading 
                                ? <SkeletonList /> 
                                : <ParamList data={params} callback={getListParams} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} />
                                }
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>
        </Box>
        <Popup open={openPopup} {...paramPopup} />
    </>);
}

export default Parametrage;