import PropTypes from 'prop-types';
import { Chip, Menu, MenuItem, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import paramApi from '../../../../api/paramApi';
import TreeSelect from '../../../../components/TreeSelect';
import { getMessageError } from '../../../../util';

UserAccess.propTypes = {
    onSelect: PropTypes.func,
    defaultValues : PropTypes.array,
};

function UserAccess({ onSelect, defaultValues = [] }) {
    const [ itemUuid, setItemUuid ] = useState(null);
    const [ data, setData ] = useState(null);
    const [ selectedItems, setSelectedItems ] = useState(defaultValues);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ searchKey, setSearchKey ] = useState('');

    const open = Boolean(anchorEl);
    const { enqueueSnackbar } = useSnackbar();

    const handleGetChilds = (newItem) => {
        setItemUuid(newItem.uuid);
    };

    const handleBack = (oldItem) => {
        setItemUuid(oldItem.parentUuid || null);
    };

    const handleSelect = (item) => {
        const isFound = selectedItems.some(selectedItem => {
            return selectedItem.code === item.code;
        });

        if (!isFound) {
            selectedItems.push(item);
            setAnchorEl(null);
            onSelect && onSelect(selectedItems);
        }
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSeach = (searchKey) => {
        setSearchKey(searchKey);
    };

    const removeSelectedItem = (code) => {
        const newSelectedItems = selectedItems.filter(item => item.code !== code);
        setSelectedItems(newSelectedItems);
        onSelect && onSelect(newSelectedItems);
    };

    useEffect(() => {
        (async () => {
            try {
                const res = itemUuid ? await paramApi.listHierarchieWith(itemUuid) : await paramApi.listHierarchie(searchKey);
                setData(res);
            } catch (error) {
                const msg = getMessageError(error, 'Échec de l\'obtention de liste de périmètre d\'accès, veuilez contacter avec le support');
                enqueueSnackbar(msg, { variant: 'error' });
            }
        })();
    }, [ itemUuid, searchKey ]);

    return (<>
        <TextField
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            label="Périmètre d'accès" 
            variant="outlined" 
            size="small" 
            fullWidth
            value=""
        />

        {selectedItems && selectedItems.map((item, index) =>
            <Chip
                key={index}
                sx={{marginTop: 1, marginRight : 1, cursor: 'pointer'}}
                label={`${item?.libelle} X`}
                color="success"
                size="small"
                onClick={() => removeSelectedItem(item.code)}
            />
        )}

        <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button' }}
                PaperProps={{ style: { width: '60ch' } }}
            >
                <MenuItem sx={{padding : 0}}>
                    <TreeSelect data={data} onGetChilds={handleGetChilds} onBack={handleBack} onSelect={handleSelect} onSearch={handleSeach} />
                </MenuItem>
            </Menu>
    </>);
}

export default UserAccess;