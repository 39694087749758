import { Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import periodApi from '../../../../api/periodApi';
import { PERIOD_STATUS } from '../../../../constants';
import { getMessageError, isAdminCommon, translateDate } from '../../../../util';
import './PeriodFilter.css';

PeriodFilter.propTypes = {
    updateFilter : PropTypes.func,
    hasError : PropTypes.bool,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
    forceReset : PropTypes.bool,
};

function PeriodFilter(props) {

    const { hasError = false, setOpenPopup, setParamPopup, forceReset, updateFilter } = props;
    const { enqueueSnackbar } = useSnackbar();

    const [ listPeriod, setListPeriod ] = useState([]);
    const [ periodSelVal, setPeriodSelVal ] = useState('');

    const history = useHistory();

    useEffect(() => {
        if (forceReset && listPeriod.length){
            handlePeriodChange(listPeriod[0]);
        }  
    }, [ forceReset ]);

    const handlePeriodChange = (period) => {
        updateFilter({ periodUuid : period.uuid, metierCode : null, societeCode : null, centreCout : null, siteCode : null, croissanceExterneCode : null });
        setPeriodSelVal(period.uuid);
    };

    const handlePeriodInfo = () => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Information',
            content : 'Aucune période ouverte ou clôturée, veuillez configurer les périodes',
            buttons : [
                ...(isAdminCommon() ? [{label : 'Configurer', color : 'button.main', onClick : () => configurerPeriod()}] : []),
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const configurerPeriod = () => {
        setOpenPopup(false);
        setParamPopup({});
        history.push('/administration/period');
    };

    useEffect(() => {
        (async () => {
            try {
                const lst = await periodApi.listOpenClose();
                if (lst && lst.length){
                    setListPeriod(lst);
                    handlePeriodChange(lst[0]);
                } else {
                    handlePeriodInfo();
                }
            } catch (error) {
                const msg = getMessageError(error, 'Échec de l\'obtention de liste des périodes ouverts et clôturé, veuilez contacter avec le support');
                enqueueSnackbar(msg, { variant: 'error' });
            } 
        })();
    }, []);

    const handleChange = (e) => {
        if (!updateFilter)  return;
        const period = listPeriod.find(p => p.uuid === e.target.value);
        handlePeriodChange(period);
    };

    return (<>
        { (listPeriod && !!listPeriod.length) && 
        <FormControl variant="outlined" fullWidth margin="dense" error={hasError}>
            <InputLabel shrink id="select-label-period">Période</InputLabel>
            <Select sx={{display : 'flex', justifyContent : 'space-between'}} required labelId="select-label-period" id="select-period" value={periodSelVal} fullWidth variant="outlined" label="Période" size="small" onChange={handleChange}>
                { listPeriod.map((item, index) => 
                    <MenuItem selected={index === 0} key={index} value={item.uuid} sx={{display : 'flex', justifyContent : 'space-between'}}>
                        <Typography>{translateDate(item.libelle)}</Typography>
                        <Chip 
                            label={item.status === PERIOD_STATUS.OUVERT.code ? PERIOD_STATUS.OUVERT.label : PERIOD_STATUS.CLOTURE.label} 
                            color={item.status === PERIOD_STATUS.OUVERT.code ? 'success' : 'default'} 
                            size="small" 
                        />
                    </MenuItem>
                )} 
            </Select>
            { hasError && <FormHelperText>Sélection obligatoire</FormHelperText> }
        </FormControl>
        }
    </>);
}

export default PeriodFilter;