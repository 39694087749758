import { Grid, Paper, Divider, IconButton, InputBase } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getCurrentYear } from '../../../../util';
import SearchIcon from '@mui/icons-material/Search';

PeriodFilter.propTypes = {
    onChange : PropTypes.func,
    yearSearch : PropTypes.number,
};

function PeriodFilter(props) {

    const { onChange, yearSearch = getCurrentYear() } = props;
    const [ year, setYear ] = useState(null);

    useEffect(() => {
        setYear(yearSearch);
    }, [yearSearch]);

    const handleClick = () => {
        if (!onChange)   return;
        onChange(year);
    };

    const handleChange = (e) => {
        setYear(e.target.value);
    };

    return (
        <Grid container spacing={2} sx={{paddingY : 2}}>
            <Grid item container xs={6}>
                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }} >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Saisir une année"
                        inputProps={{ 'aria-label': 'Saisir une année', variant : 'outlined' }}
                        onChange={handleChange}
                        fullWidth
                        name='skey'
                        value={year}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleClick}>
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default PeriodFilter;