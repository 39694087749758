import React, { useEffect, useRef } from 'react';
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import PropTypes from 'prop-types';

const OktaSignInWidget = ({ config, onSuccess, onError }) => {
  
  const widgetRef = useRef();

  useEffect(() => {
    // Hide help text on load
    setTimeout(() => {
      const selector = document.querySelectorAll('[data-se="needhelp"]');
      if (selector.length) selector[0].style.display = 'none';
    }, 50);
  }, []);

  useEffect(() => {
    if (!widgetRef.current) return false;

    const widget = new OktaSignIn(config);

    widget.showSignInToGetTokens({ el: widgetRef.current })
    .then(onSuccess)
    .catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};

OktaSignInWidget.propTypes = {
  config : PropTypes.object,
  onSuccess : PropTypes.func,
  onError : PropTypes.func,
};

export default OktaSignInWidget;
