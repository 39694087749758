import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

SelectField.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    items : PropTypes.array.isRequired,
    onChange : PropTypes.func,
};

function SelectField(props) {
    const { form, name, label, disabled, items, onChange } = props;
    const { formState: { errors } } = form;
    const hasError = errors[name];

    const handleChange = (e) => {
        if (!onChange)   return;
        onChange(e);
    };

    return (
        <Controller
        name={name}
        control={form.control}
        render={({ field: { onChange, onBlur, value, name } }) => (
            <FormControl variant="outlined" fullWidth margin="dense">
                <InputLabel id={`sel-label-${name}`}>{label}</InputLabel>
                <Select
                    labelId={`sel-label-${name}`}
                    id={`sel-id-${name}`}
                    value={value}
                    name={name}
                    onChange={(e)=> {
                        onChange(e);
                        handleChange(e);
                    }}
                    label={label}
                    error={!!hasError}
                    disabled={disabled}
                    onBlur={onBlur}
                    fullWidth
                    variant="outlined"
                   
                    size="small"
                >
                    {
                        items.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        )}
        />
    );
}

export default SelectField;