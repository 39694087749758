import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { formatDate, formatDateTime } from './../../../util/date';
import { Box, Button } from '@mui/material';
import exportXlsPopulate from '../../../components/Export/exportXlsPopulate';
import { CSVLink } from 'react-csv';

ExportExcel.propTypes = {
    data : PropTypes.array,
};

const headers = [
    { label: 'Source', key: 'source' },
    { label: 'Date de la période', key: 'datePeriod' },
    { label: 'Code du métier', key: 'metierCode' },
    { label: 'Libéllé du métier', key: 'metierLibelle' },
    { label: 'Code de la société', key: 'societeCode' },
    { label: 'Libéllé de la société', key: 'societeLibelle' },
    { label: 'Code du centre de coût', key: 'centreCoutCode' },
    { label: 'Libéllé du centre de coût', key: 'centreCoutLibelle' },
    { label: 'Code de la location', key: 'siteCode' },
    { label: 'Libéllé de la location', key: 'siteLibelle' },
    { label: 'Code de la croissance externe', key: 'croissanceExterneLibelle' },
    { label: 'Code du KPI', key: 'kpiCode' },
    { label: 'Libéllé du KPI', key: 'kpiLibelle' },
    { label: 'Valeur réalisé', key: 'valeurRealise' },
    { label: 'Valeur ajusté', key: 'valeurAjuste' },
    { label: 'Valeur ajusté M-1', key: 'valeurAjusteM1' },
    { label: 'Modifié par', key: 'modifyBy' },
    { label: 'Modifié date', key: 'modifyDate' },
];

function ExportExcel(props) {

    const { data } = props;
    const csvLink = useRef();

    const newData = data
        .map(item => ({ ...item, datePeriod : formatDate(item.datePeriod), modifyDate : formatDateTime(item.modifyDate) }))
        .map(item => {
            delete item.id;
            return item;
        });

    const handleExportXlsx = () => {
        exportXlsPopulate(newData, 'kpis_ajuste_infomation_xlsx', headers);
    };

    const handleExportCsv = () => {
        csvLink.current.link.click();
    };
    
    return (
        <>
            <Box sx={{marginRight : 2}}>
                <Button 
                    sx={{
                        textTransform : 'none', 
                        bgcolor : 'button.main', 
                        color : 'buttonText.main',
                        fontWeight : 'bold',
                        '&:hover' : {
                            bgcolor : 'button.main',
                            boxShadow : '0 5px 15px -10px rgba(31, 18, 53, 0.6)',
                        }
                    }} 
                    size="small" variant="contained"
                    onClick={handleExportXlsx}>
                        Export XLSX
                    </Button>
            </Box>
            <Box>
                <Button 
                    sx={{
                        textTransform : 'none', 
                        bgcolor : 'button.main', 
                        color : 'buttonText.main',
                        fontWeight : 'bold',
                        '&:hover' : {
                            bgcolor : 'button.main',
                            boxShadow : '0 5px 15px -10px rgba(31, 18, 53, 0.6)',
                        }
                    }} 
                    size="small" variant="contained"
                    onClick={handleExportCsv}>
                        Export CSV
                    </Button>
                <CSVLink headers={headers} data={newData} filename="kpis_ajuste_infomations_csv.csv" style={{ 'textDecoration' : 'none'}} ref={csvLink} />
            </Box>
        </>
    );
}

export default ExportExcel;