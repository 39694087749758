import { Chip, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import periodApi from './../../../api/periodApi';
import { PERIOD_STATUS } from './../../../constants';
import { translateDate, getMessageError } from './../../../util';

ExportFilter.propTypes = {
    onChange : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
    periodUuid : PropTypes.string,
};

function ExportFilter(props) {
    const { onChange, setOpenPopup, setParamPopup, periodUuid } = props;

    const history = useHistory();

    const { enqueueSnackbar } = useSnackbar();
    const [ listPeriod, setListPeriod ] = useState([]);
    const [ periodSelVal, setPeriodSelVal ] = useState('');

    useEffect(() => {
        (async () => {
            try {
                const lst = await periodApi.listOpenClose();
                if (lst && lst.length){
                    setListPeriod(lst);
                    handleLoadPageWithUuid(lst);
                } else {
                    handlePeriodInfo();
                }
            } catch (error) {
                const msg = getMessageError(error, 'Échec de l\'obtention de liste des périodes ouverts et clôturé, veuilez contacter avec le support');
                enqueueSnackbar(msg, { variant: 'error' });
            } 
        })();
    }, []);

    const handleLoadPageWithUuid = (list) => {
        if (!periodUuid){
            onChange({ periodUuid : list[0].uuid});
            setPeriodSelVal(list[0].uuid);
            return;
        }

        if (list.findIndex(p => p.uuid === periodUuid) < 0){
            history.push(`/administration/export?periodUuid=${list[0].uuid}`);
            return;
        }

        setPeriodSelVal(periodUuid);
    };

    const handlePeriodInfo = () => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Information',
            content : 'Aucune période ouverte ou clôturée, veuillez configurer les périodes',
            buttons : [
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const handleChange = (e) => {
        if (!onChange)  return;
        const periodSel = listPeriod.find(p => p.uuid === e.target.value);
        onChange({ periodUuid : periodSel.uuid });
        setPeriodSelVal(periodSel.uuid);
    };

    return (
        <Grid container spacing={2} sx={{paddingY : 2}}>
            <Grid item container xs={12}>
            { (listPeriod && !!listPeriod.length) && 
                <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel shrink id="select-label-period">Période</InputLabel>
                    <Select sx={{display : 'flex', justifyContent : 'space-between'}} required labelId="select-label-period" id="select-period" value={periodSelVal} fullWidth variant="outlined" label="Période" size="small" onChange={handleChange}>
                        { listPeriod.map((item, index) => 
                            <MenuItem selected={index === 0} key={index} value={item.uuid} sx={{display : 'flex', justifyContent : 'space-between'}}>
                                <Typography>{translateDate(item.libelle)}</Typography>
                                <Chip 
                                    label={item.status === PERIOD_STATUS.OUVERT.code ? PERIOD_STATUS.OUVERT.label : PERIOD_STATUS.CLOTURE.label} 
                                    color={item.status === PERIOD_STATUS.OUVERT.code ? 'success' : 'default'} 
                                    size="small" 
                                />
                            </MenuItem>
                        )} 
                    </Select>
                </FormControl>
            }
            </Grid>
        </Grid>
    );
}

export default ExportFilter;