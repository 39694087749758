import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import paramApi from '../api/paramApi';
import { getMessageError } from '../util';

export default function(){
  const [ params, setParams ] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
          const res = await paramApi.listParams();
          setParams(res);
      } catch (error) {
        const msg = getMessageError(error, 'Une erreur est survenue de récupération la liste de paramètrages, veuilez contacter avec le support');
        enqueueSnackbar(msg, { variant: 'error' });
      }
    })();
  }, []);

  return params;
}