import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import jobApi from '../../../../api/jobApi';
import periodApi from '../../../../api/periodApi';
import SelectField from '../../../../components/form-controls/SelectField';
import { translateDate } from '../../../../util';

JobFormAdd.propTypes = {
    onSubmit : PropTypes.func,
};

function JobFormAdd(props) {

    const { onSubmit } = props;
    const [ lstPeriod, setLstPeriod ] = useState([]);
    const [ lstJob, setLstJob ] = useState([]);
    const [ periodUuidSel, setPeriodUuidSel ] = useState(null);

    const handleChangePeriod = (e) => {
        setPeriodUuidSel(e.target.value);
    };

    useEffect(() => {
        (async () => {
            try {
                let res = await periodApi.listOpen();
                if (res && res.length){
                    res = res.map(j => ({ ...j, value : j.uuid, label : translateDate(j.libelle)}));
                }
                setLstPeriod(res);
            } catch (error) {
                setLstPeriod([]);
            }
        })();
    }, []);

    useEffect(() => {
        if (periodUuidSel){
            setLstJob([]);
            (async () => {
                try {
                    let res = await jobApi.listJob(periodUuidSel);
                    if (res && res.length){
                        res = res.map(j => ({ ...j, value : j.nom, label : j.libelleTraitement}));
                    }
                    setLstJob(res);
                } catch (error) {
                    setLstJob([]);
                }
            })();
        }
    }, [ periodUuidSel ]);

    const schema = yup.object().shape({
        periodUuid: yup.string().required('Veuillez choisir une période').test(
            'empty-check', 
            'Veuillez choisir une période', 
            uuid => uuid.trim().length !== 0
        ),
        nomJob: yup.string().required('Veuillez choisir un job').test(
            'empty-check', 
            'Veuillez choisir un job', 
            job => job.trim().length !== 0
        ),
    });

    const form = useForm({
        defaultValues: {
            periodUuid : ' ',
            nomJob : ' ',
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        if (!onSubmit)  return;
        onSubmit(values);
    };

    return (
        <Box sx={{marginTop : 2, fontWeight : 'bold', fontSize : '20px'}}>
            <form onSubmit={form.handleSubmit(handleSubmit)} sx={{margin : 1}}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <SelectField name="periodUuid" label="Choisir une période" form={form} items={lstPeriod} onChange={handleChangePeriod} />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectField name="nomJob" label="Choisir un job" form={form} items={lstJob} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display="none">
                        <Button variant="contained" color="primary" type="submit" id="form-submit"> Enregistrer </Button>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}

export default JobFormAdd;