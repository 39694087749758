import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { translateDate } from '../../../util';
import KpiItem from './components/KpiItem';

Kpi.propTypes = {
    data : PropTypes.array,
    onAllUpdate : PropTypes.func,
    onChange : PropTypes.func,
    periodShow : PropTypes.object,
    filters : PropTypes.object,
    periodPrevious : PropTypes.object,
    onReset : PropTypes.func,
};

function Kpi(props) {

    const { data, onAllUpdate, onChange, periodShow, periodPrevious, onReset, filters } = props;
    const hasChanged = data.some(kpi => kpi.kpiDetail.valeurAjuste !== kpi.kpiDetail.ajustementInit);

    const handleChange = (kpiId, newValue) => {
        if (!onChange)   return;
        onChange(kpiId, newValue);
    };

    const handleClickSave = () => {
        if (!onAllUpdate)   return;
        onAllUpdate();
    };

    const handleClickReset = () => {
        if (!onReset)   return;
        onReset();
    };

    return (
        <Box>
            { periodShow && 
            <TableContainer component={Paper} sx={{marginTop : 2}}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table" size='small'>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{fontWeight : 'bold'}} colSpan={2}>{translateDate(periodShow.libelle)}</TableCell>
                            <TableCell colSpan={2}>
                                {
                                    hasChanged &&
                                    <Box sx={{display : 'flex', justifyContent : 'flex-end'}}>
                                        <Button onClick={handleClickSave} sx={{bgcolor: 'button.main', textTransform: 'none', color: 'buttonText.main', fontWeight: 'bold', '&:hover' : {bgcolor: 'button.main'}}} variant="contained" color="primary" size="small">
                                            Mise à jour
                                        </Button>
                                        <Button onClick={handleClickReset} sx={{bgcolor: 'button.default', marginLeft : 1, textTransform: 'none', color: 'buttonText.main', fontWeight: 'bold', '&:hover' : {bgcolor: 'button.default'}}} variant="contained" color="primary" size="small">
                                            Réinitialiser
                                        </Button>
                                    </Box>
                                }
                            </TableCell>
                            <TableCell sx={{fontWeight : 'bold', borderLeft : '1px solid'}} colSpan={3}>{translateDate(periodPrevious.libelle)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{fontWeight : 'bold'}}>{'KPI(s)'}</TableCell>
                            <TableCell sx={{fontWeight : 'bold'}} align="right">Réalisé</TableCell>
                            <TableCell sx={{fontWeight : 'bold'}} align="right">Ajustement</TableCell>
                            <TableCell sx={{fontWeight : 'bold'}} align="right">Total</TableCell>

                            <TableCell sx={{fontWeight : 'bold', borderLeft : '1px solid'}} align="right">Réalisé</TableCell>
                            <TableCell sx={{fontWeight : 'bold'}} align="right">Ajustement</TableCell>
                            <TableCell sx={{fontWeight : 'bold'}} align="right">Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        { data.map((item, index) => (
                            <KpiItem key={index} kpi={item} index={index} onChange={handleChange} periodShow={periodShow} filters={filters} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            }
            
        </Box>
    );
}

export default Kpi;