import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import metierApi from '../../../../api/metierApi';
import Loading from '../../../../components/Loading';
import { getMessageError } from '../../../../util';
import MetierFormAdd from './MetierFormAdd';

MetierAdd.propTypes = {
    callback : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
};

function MetierAdd(props) {

    const { callback, setOpenPopup, setParamPopup } = props;
    const [ loading, setLoading ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleMAddUser = () => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Ajouter un métier',
            form : <MetierFormAdd onSubmit={submitAdd} />,
            buttons : [
                {label : <label htmlFor='form-submit'>Ajouter</label>, color : 'button.main'},
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ],
        });
    };

    const submitAdd = async (user) => {
        setLoading(true);
        try {
            await metierApi.new(user);
            enqueueSnackbar('Ajouter avec succès', { variant: 'success' });

            if (callback)    callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en ajoutant un métier, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
            setOpenPopup(false);
            setParamPopup({});
        }
    };

    return (<>
        { loading && <Loading/> }
        <Box>
            <Button 
            sx={{
                textTransform : 'none', 
                bgcolor : 'button.main', 
                color : 'buttonText.main',
                fontWeight : 'bold',
                '&:hover' : {
                    bgcolor : 'button.main',
                    boxShadow : '0 5px 15px -10px rgba(31, 18, 53, 0.6)',
                }
            }} 
            size="small" variant="contained" onClick={handleMAddUser}>
                Nouveau
            </Button>
        </Box>
    </>);
}

export default MetierAdd;