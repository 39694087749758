export const formatNumber = (number) => {
    if (!number)    return null;
    const intl = new Intl.NumberFormat();
    return intl.format(number);
};

export const getMessageError = (error = {}, msgDefault) => {
    console.log('msg', msgDefault);
    const { code, msg } = error;
    return code ? msg : msgDefault;
};