import axios from 'axios';
import ERRORS_CODES from '../constants/errorCode';

const axiosClient = axios.create({
  headers: {
      'Content-Type': 'application/json',
  },
  withCredentials : true,
  timeout : 10 * 60 * 1000
});

axiosClient.interceptors.request.use(
  (config) => {
    const { accessToken } = JSON.parse(localStorage.getItem('okta-token-storage') || '{}');
    
    if (accessToken?.accessToken){
      config.headers.Authorization = `${accessToken.tokenType} ${accessToken.accessToken}`;
    }
    return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {

    console.log(ERRORS_CODES);
    const { response : { data } } = error;

    if (ERRORS_CODES[data.code] !== null){
      return Promise.reject(ERRORS_CODES[data.code]);
    }

    return Promise.reject(error);
  }
);
  
export default axiosClient;