import { PROFILES_CODES } from '../constants';
import { STORAGES_KEYS } from '../constants/storages-keys';

const getToken = () => {
    return localStorage.getItem(STORAGES_KEYS.TOKEN);
};

const removeUserFromLocalStorage = () => {
    localStorage.removeItem(STORAGES_KEYS.USER);
    localStorage.removeItem(STORAGES_KEYS.TOKEN);
};

const isSuperAdmin = () => {
    const me = JSON.parse(localStorage.getItem(STORAGES_KEYS.USER) || '{}');
    return PROFILES_CODES.SUPER_ADM === me.profile;
};

const isAdmin = () => {
    const me = JSON.parse(localStorage.getItem(STORAGES_KEYS.USER) || '{}');
    return PROFILES_CODES.ADMINISTRATOR === me.profile;
};

const isAdminCommon = () => {
    return isSuperAdmin() || isAdmin();
};

const getUserInfo = () => {
    return JSON.parse(localStorage.getItem(STORAGES_KEYS.USER) || '{}');
};

const logout = () => {
    localStorage.clear();
    window.location.href = '/login';
};

export { logout, getToken, removeUserFromLocalStorage, isSuperAdmin, isAdmin, getUserInfo, isAdminCommon };
