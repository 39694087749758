import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

Lotties.propTypes = {
    animation : PropTypes.any,
    loop : PropTypes.bool,
    autoplay : PropTypes.bool,
    height : PropTypes.number,
    width : PropTypes.number,
};

function Lotties(props) {
    const { animation, loop = true, autoplay = true, height = 400, width = 400 } = props;

    const defaultOptions = {
        loop,
        autoplay,
        animationData: animation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div>
            <Lottie options={defaultOptions} height={height} width={width} />
        </div>
    );
}

export default Lotties;