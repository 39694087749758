import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import jobApi from '../../../../api/jobApi';
import Loading from '../../../../components/Loading';
import { getMessageError } from '../../../../util';
import JobItem from './JobItem';

JobList.propTypes = {
    data : PropTypes.array,
    callback : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
};

function JobList(props) {
    const { data = [], callback, setOpenPopup, setParamPopup } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false);

    const handleDelJob = (job) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Confirmation',
            content : <>Vous êtes sûr de supprimer le job <b>{job.nomJobLibelle}</b></>,
            buttons : [
                {label : 'Supprimer', color : 'button.main', onClick : () => delJob(job)},
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const delJob = async (job) => {
        setLoading(true);
        try {
            await jobApi.delete(job.id);
            enqueueSnackbar('Supprimer le job avec succès', { variant: 'success' });
            callback && callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en supprimant ce job, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
            setOpenPopup(false);
            setParamPopup({});
        }
    };

    return (<>
        { loading && <Loading/> }
        <TableContainer component={Paper}>
            <Table sx={{minWidth : 650}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight : 'bold'}}>Période</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>{'Date d\'ouverture'}</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Libéllé</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Statut</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Date de demande</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Date de traitement</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Utilisateur</TableCell>
                        <TableCell align="center" sx={{fontWeight : 'bold'}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Boolean(data.length) && data.map((job, index) => (
                            <JobItem key={index} job={job} onDel={handleDelJob} />
                        ))
                    }
                    {
                        Boolean(!data.length) && (
                            <TableRow hover >
                                <TableCell colSpan={9} sx={{textAlign : 'center'}}>Aucune job trouvé</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>);
}

export default JobList;