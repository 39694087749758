import { Link, Typography, Box } from '@mui/material';
import React from 'react';
import Lotties from '../Lotties';
import NotFoundAnimation from './../../assets/lotties/404.json';

NotFound.propTypes = {
};

function NotFound() {

    return (
        <Box>
            <Lotties animation={NotFoundAnimation} />
            <Typography textAlign="center">
                <Link sx={{color : 'headline.main'}} href="/">{'Retour à la page d\'accueil'}</Link>
            </Typography>
        </Box>
    );
}

export default NotFound;