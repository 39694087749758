import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/user';

const userApi = {
    async list(params){
        const url = `${BASE_URL}/list`;
        return axiosClient.post(url, params);
    },
    async get(){
        const url = `${BASE_URL}/get`;
        return axiosClient.get(url);
    },
    async find(identifiant){
        const url = `${BASE_URL}/find/${identifiant}`;
        return axiosClient.get(url);
    },
    async new(params){
        const url = `${BASE_URL}/new`;
        return axiosClient.put(url, params);
    },
    async edit(params, identifiant){
        const url = `${BASE_URL}/edit/${identifiant}`;
        return axiosClient.post(url, params);
    },
    async listAll(){
        const url = `${BASE_URL}/listAll`;
        return axiosClient.get(url);
    },
};

export default userApi;