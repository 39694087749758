import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import jobApi from '../api/jobApi';
import { NOM_JOB_LIST } from '../constants/storages-keys';
import { getMessageError } from '../util';

export default function(){
  const [ nomJobList, setNomJobList ] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  
  useEffect(() => {
    (async () => {
      try {
        let list = JSON.parse(localStorage.getItem(NOM_JOB_LIST));
        if (!list){
          const res = await jobApi.allJob();
          localStorage.setItem(NOM_JOB_LIST, JSON.stringify(res));
          list = res;
        }
        setNomJobList(list);
      } catch (error) {
        const msg = getMessageError(error, 'Ne peut pas télécharger la list des nom job, veuilez contacter avec le support');
        enqueueSnackbar(msg, { variant: 'error' });
      }
    })();
  }, []);

  return nomJobList;
}