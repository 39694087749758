import { saveAs } from 'file-saver';
import XlsxPopulate from 'xlsx-populate';

const exportXlsPopulate = async (data = [], fileName = 'file', headers) => {

    const header = headers.map(item => item.label);

    const workbook = await XlsxPopulate.fromBlankAsync();
    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(data, header);
    const totalColumns = sheetData[0].length;

    sheet1.cell('A1').value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style('bold', true);
    sheet1.range('A1:' + endColumn + '1').style('fill', 'BFBFBF');
    range.style('border', true);

    const res = await workbook.outputAsync();
    saveAs(res, `${fileName}.xlsx`);
};

const getSheetData = (data, header) => {
    const fields = Object.keys(data[0]);
    const sheetData = data.map((row) => {
        return fields.map(fieldName => row[fieldName] || '');
    });
    sheetData.unshift(header);

    return sheetData;
};

export default exportXlsPopulate;