import React from 'react';
import { DataGrid, frFR } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { formatDate } from './../../../util/date';
import { formatNumber } from './../../../util';
import './ExportList.css';

ExportList.propTypes = {
    data : PropTypes.array,
};

function ExportList(props) {

    const { data } = props;

    const columns = [
        { field: 'source', headerName: 'Source', width: 70 },
        { field: 'datePeriod', headerName: 'Date de période', width: 130, valueGetter: (params) => formatDate(params.row.datePeriod) },
        { field: 'metierLibelle', headerName: 'Métier', width: 90, valueGetter:(params) => params.row.metierLibelle ? params.row.metierLibelle : params.row.metierCode },
        { field: 'societeLibelle', headerName: 'Société', width: 90, valueGetter:(params) => params.row.societeLibelle ? params.row.societeLibelle : params.row.societeCode },
        { field: 'centreCoutLibelle', headerName: 'Centre de coût', width: 200, valueGetter:(params) => params.row.centreCoutLibelle ? params.row.centreCoutLibelle : params.row.centreCoutCode },
        { field: 'siteLibelle', headerName: 'Location', width: 200, valueGetter:(params) => params.row.siteLibelle ? params.row.siteLibelle : params.row.siteCode },
        { field: 'croissanceExterneLibelle', headerName: 'Croissance externe', width: 90 },
        { field: 'kpiLibelle', headerName: 'KPI', width: 200 },
        { field: 'valeurRealise', headerName: 'Valeur réalisé', width: 100, valueGetter : (params) => formatNumber(params.row.valeurRealise) },
        { field: 'valeurAjuste', headerName: 'Valeur ajusté', width: 100, valueGetter : (params) => formatNumber(params.row.valeurAjuste) },
        { field: 'valeurAjusteM1', headerName: 'Valeur ajusté M-1', width: 130, valueGetter : (params) => formatNumber(params.row.valeurAjusteM1) },
        { field: 'modifyBy', headerName: 'Modifié par', width: 130 },
        { field: 'modifyDate', headerName: 'Date modifié', width: 100, valueGetter: (params) => formatDate(params.row.modifyDate) },
    ];
    
    return (
        <div style={{ width: '100%' }}>
            <DataGrid
                rows={data}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                autoHeight={true}
            />
        </div>
    );
}

export default ExportList;