import { Box, Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import paramApi from '../../../../api/paramApi';
import Loading from '../../../../components/Loading';
import { getMessageError } from '../../../../util';
import ParamAddForm from './ParamAddForm';

ParamAdd.propTypes = {
    callback : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
};

function ParamAdd(props) {

    const { callback, setOpenPopup, setParamPopup } = props;
    const [ loading, setLoading ] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleMAddParam = () => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Ajouter des périodes',
            form : <ParamAddForm onSubmit={submitAddParam} />,
            buttons : [
                {label : <label htmlFor='form-submit'>Ajouter</label>, color : 'button.main'},
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ],
        });
    };

    const submitAddParam = async (params) => {
        setLoading(true);
        try {
            await paramApi.addParams(params);
            enqueueSnackbar('Ajouter avec succès', { variant: 'success' });

            if (callback)    callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue, peut-être le code est existé, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
            setOpenPopup(false);
            setParamPopup({});
        }
    };

    return (<>
        { loading && <Loading/> }
        <Box>
            <Button 
            sx={{
                textTransform : 'none', 
                bgcolor : 'button.main', 
                color : 'buttonText.main',
                fontWeight : 'bold',
                '&:hover' : {
                    bgcolor : 'button.main',
                    boxShadow : '0 5px 15px -10px rgba(31, 18, 53, 0.6)',
                }
            }}  
            size="small" variant="contained" onClick={handleMAddParam}>
                Nouveau
            </Button>
        </Box>
    </>);
}

export default ParamAdd;