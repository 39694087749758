import React from 'react';
import { LinearProgress } from '@mui/material';

Loading.propTypes = {
};

function Loading() {
    return (
        <LinearProgress sx={{position : 'absolute', top : 0, left: 0, right : 0, zIndex : 2000}} />
    );
}

export default Loading;