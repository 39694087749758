
const getOktaAuthConfig = (config) => {
  if (!config) return;
  return {
    issuer: config.OKTA_ISSUER,
    clientId: config.OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
  };
};

const getOktaSignInConfig = (config) => {
  if (!config) return;
  return {
    baseUrl: config.OKTA_URL,
    clientId: config.OKTA_CLIENT_ID,
    redirectUri: window.location.origin + '/login/callback',
    logo: 'https://res.cloudinary.com/dsj6x122z/image/upload/v1638050037/ajustement_kpi_1_rggklb.png',
    language: 'fr',
    authParams: {
      scopes: ['openid', 'profile', 'email']
    }
  };
};

export { getOktaAuthConfig, getOktaSignInConfig };
