import { createTheme } from '@mui/material';
import { orange } from '@mui/material/colors';

const theme = createTheme({
    palette : { 
        status: {
            danger: orange[500],
        },
        background : {
            main : '#f2f7f5',
        },
        headline : {
            main : '#00473e',
        },
        paragraph : {
            main : '#475d5b',
        },
        button : {
            main : '#faae2b',
            default : '#e8e4e6',
        },
        buttonText : {
            main : '#00473e',
        },
        stroke : {
            main : '#00332c',
        },
        secondary : {
            main : '#ffa8ba',
        },
        highlight : {
            main : '#faae2b',
        },
        tertiary : {
            main : '#fa5246',
        },
    }
});

export default theme;