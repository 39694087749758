import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Loading from '../../../../components/Loading';
import KpiParamItem from './KpiParamItem';
import paramApi from '../../../../api/paramApi';
import { getMessageError } from '../../../../util';

kpiParamList.propTypes = {
    data : PropTypes.array,
    callback : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
};

const getPopupContent = (kpiParam, action) => {
    switch (action){
        case 'REPORTABLE' : return <>{'Vous êtes sûr de vouloir reporter les valeurs du M-1'} <b>{kpiParam.kpiCode} - {kpiParam.metierCode}</b> </>;
        case 'NOT_REPORTABLE' : return <>{'Vous êtes sûr de vouloir annuler le report des valeurs du M-1'} <b>{kpiParam.kpiCode} - {kpiParam.metierCode}</b></>;
        default : '';
    }
};

function kpiParamList(props) {
    const { data = [], callback, setOpenPopup, setParamPopup } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false);

    const handleState = async (kpiParam, values, action) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Confirmation',
            content : getPopupContent(kpiParam, action),
            buttons : [
                {label : 'Mettre à jour', color : 'button.main', onClick : () => toggleState(kpiParam, values)},
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const toggleState = async (kpiParam, values) => {
        setLoading(true);
        try {
            const modifiedKpiParam = { ...kpiParam, ...values };
            await paramApi.postKpiParams(modifiedKpiParam);
            const { metierCode } = modifiedKpiParam;
            enqueueSnackbar('Mise à jour effectuée avec succès.', { variant: 'success' });
            callback && callback(metierCode);
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue lors de la mise à jour de ce paramétrage de KPI, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
            setOpenPopup(false);
            setParamPopup({});
        }
    };

    return (<>
        { loading && <Loading/> }
        <TableContainer component={Paper}>
            <Table sx={{minWidth : 650}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight : 'bold'}}>Libellé KPI</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Métier analytique</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Report M-1</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Utilisateur</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Date Mise à jour</TableCell>
                        <TableCell align="center" sx={{fontWeight : 'bold'}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Boolean(data.length) && data.map((kpiParam, index) => (
                            <KpiParamItem key={index} kpiParam={kpiParam} onToggle={handleState} />
                        ))
                    }
                    {
                        Boolean(!data.length) && (
                            <TableRow hover >
                                <TableCell colSpan={9} sx={{textAlign : 'center'}}>Aucun paramétrage KPI trouvé</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>);
}

export default kpiParamList;