import { Box, Button, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import jobApi from '../../../../api/jobApi';
import Loading from '../../../../components/Loading';
import { getMessageError } from '../../../../util';
import JobFormAdd from './JobFormAdd';

JobAdd.propTypes = {
    callback : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
};

const MSG_POPUP = {
    Alim_KPI : { content : <>{'Confirmez-vous vouloir créer un traitement d\'import référentiel KPI'} ?</> },
    Alim_KPI_Detail : { content : <>{'Confirmez-vous vouloir créer un traitement d\'import référentiel du mois'} ?</> },
    TRAITEMENT_REFERENTIEL_MANUEL : { content : <>{'Confirmez-vous vouloir recharger le référentiel'} ? <br/><br/>  
        <Typography variant='body2' sx={{ fontStyle : 'italic', textAlign : 'justify'}}>{'*Attention, ce traitement peut durer 1 à 2 minutes (Il supprime puis recréé le référentiel pour cette période).'} </Typography>
    </> },
};

function JobAdd(props) {

    const { callback, setOpenPopup, setParamPopup } = props;
    const [ loading, setLoading ] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const handleAddJob = () => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Demande de traitement',
            form : <><JobFormAdd onSubmit={confirmJob} /></>,
            buttons : [
                {label : <label htmlFor='form-submit'>Soumettre</label>, color : 'button.main'},
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ],
        });
    };

    const addJob = async (values) => {
        setLoading(true);
        try {
            await jobApi.new(values);
            enqueueSnackbar('Ajouter avec succès', { variant: 'success' });

            if (callback)    callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en ajoutant un traitement, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            resetState();
        }
    };

    const confirmJob = (values) => {
        setParamPopup({
            title : 'Information',
            content : MSG_POPUP[values.nomJob].content,
            buttons : [
                {label : 'Confirmer', color : 'button.main', onClick : () => addJob(values)},
                {label : 'Annuler', color : 'inherit', onClick : resetState},
            ]
        });
    };

    const resetState = () => {
        setLoading(false);
        setOpenPopup(false);
        setParamPopup({});
    };

    return (<>
        { loading && <Loading/> }
        <Box>
            <Button 
            sx={{
                textTransform : 'none', 
                bgcolor : 'button.main', 
                color : 'buttonText.main',
                fontWeight : 'bold',
                '&:hover' : {
                    bgcolor : 'button.main',
                    boxShadow : '0 5px 15px -10px rgba(31, 18, 53, 0.6)',
                },
                marginRight : 2
            }} 
            size="small" variant="contained" onClick={handleAddJob}>
                Nouveau
            </Button>
        </Box>
    </>);
}

export default JobAdd;