import axiosClient from './axiosClient';

const BASE_URL = '/v1/api/period';

const periodApi = {
    async list(year){
        const url = `${BASE_URL}/list/${year}`;
        return axiosClient.get(url);
    },
    async new(params){
        const url = `${BASE_URL}/new`;
        return axiosClient.post(url, params);
    },
    async generate(year, applyAjustable){
        const url = `${BASE_URL}/generate/${year}/${applyAjustable}`;
        return axiosClient.get(url);
    },
    async delete(uuid){
        const url = `${BASE_URL}/${uuid}`;
        return axiosClient.delete(url);
    },
    async edit(uuid, params){
        const url = `${BASE_URL}/edit/${uuid}`;
        return axiosClient.post(url, params);
    },
    async listOpenClose(){
        const url = `${BASE_URL}/listOpenClose`;
        return axiosClient.get(url);
    },
    async listOpen(){
        const url = `${BASE_URL}/listOpen`;
        return axiosClient.get(url);
    },
};

export default periodApi;