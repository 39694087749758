import { IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import homeApi from '../../../../api/homeApi';
import periodApi from '../../../../api/periodApi';
import Loading from '../../../../components/Loading';
import { getMessageError, translateDate } from '../../../../util';
import PeriodEditForm from './PeriodEditForm';
import PeriodItem from './PeriodItem';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

PeriodList.propTypes = {
    data : PropTypes.array,
    callback : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
    onSortStatus : PropTypes.func,
    onSortDateDebut : PropTypes.func,
};

function PeriodList(props) {

    const { data = [], callback, setOpenPopup, setParamPopup, onSortStatus, onSortDateDebut } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false);
    const [ sortAscStatus, setSortAscStatus ] = useState(true);
    const [ sortAscDateDebut, setSortAscDateDebut ] = useState(true);

    const handleDelPeriod = async (period) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Confirmation',
            content : <>{'Vous êtes sûr de supprimer la periode '} <b>{translateDate(period.libelle)}</b> ? </>,
            buttons : [
                {label : 'Supprimer', color : 'button.main', onClick : () => delPeriod(period)},
                {label : 'Annuler', color : 'inherit', onClick : resetState},
            ]
        });
    };

    const delPeriod = async (period) => {
        setLoading(true);
        try {
            await periodApi.delete(period.uuid);
            enqueueSnackbar('Supprimer la période avec succès.', { variant: 'success' });
            callback && callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en supprimant cette période, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            resetState();
        }
    };

    const resetState = () => {
        setLoading(false);
        setOpenPopup(false);
        setParamPopup({});
    };

    const handleEditPeriod = async (period) => {
        setOpenPopup(true);
        setParamPopup({
            title : <>{'Modification la periode '} <b>{translateDate(period.libelle)}</b></>,
            form : <PeriodEditForm period={period} onSubmit={editPeriod} />,
            buttons : [
                {label : <label htmlFor='form-submit'>Mettre à jour</label>, color : 'button.main' },
                {label : 'Annuler', color : 'inherit', onClick : resetState},
            ]
        });
    };

    const editPeriod = async (uuid, values) => {
        setLoading(true);
        try {
            await periodApi.edit(uuid, values);
            enqueueSnackbar('Modifier la période avec succès.', { variant: 'success' });
            callback && callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en modifiant cette période, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            resetState();
        }
    };

    const handleChargerManuelRefPeriod = async (period) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Confirmation',
            content : <>{'Vous êtes sûr de déclencher manuellement le traitement référentiel de cette periode '} <b>{translateDate(period.libelle)}</b> ? </>,
            buttons : [
                {label : 'Charger', color : 'button.main', onClick : () => chargerRefManuelPeriod(period)},
                {label : 'Annuler', color : 'inherit', onClick : resetState},
            ]
        });
    };

    const chargerRefManuelPeriod = async (period) => {
        setLoading(true);
        try {
            await homeApi.refManual(period.libelle);
            enqueueSnackbar('Chargement manuellement le référentiel de la période avec succès.', { variant: 'success' });
            callback && callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en chargant manuellement le référentiel de la période, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            resetState();
        }
    };

    const handleSortStatus = () => {
        setSortAscStatus(!sortAscStatus);
        onSortStatus && onSortStatus(!sortAscStatus);
    };

    const handleSortDateDebut = () => {
        setSortAscDateDebut(!sortAscDateDebut);
        onSortDateDebut && onSortDateDebut(!sortAscDateDebut);
    };

    return (<>
        { loading && <Loading/> }
        <TableContainer component={Paper}>
            <Table sx={{minWidth : 650}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight : 'bold'}}>Libéllé</TableCell>
                        <TableCell sx={{fontWeight : 'bold', '&:hover .sort-status' : { visibility : 'visible'}}}>Date de début
                            <IconButton onClick={handleSortDateDebut} sx={{visibility : 'hidden', marginLeft : 1}} className='sort-status' aria-label="Trier par date début" size="small">
                                { sortAscDateDebut ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Date de fin</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>{'Date d\'ouverture'}</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Date cloture</TableCell>
                        <TableCell sx={{fontWeight : 'bold', '&:hover .sort-status' : { visibility : 'visible'}}}>Statut
                            <IconButton onClick={handleSortStatus} sx={{visibility : 'hidden', marginLeft : 1}} className='sort-status' aria-label="Trier par status" size="small">
                                { sortAscStatus ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                            </IconButton>
                        </TableCell>
                        <TableCell align="center" sx={{fontWeight : 'bold'}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Boolean(data.length) && data.map((item, index) => (
                            <PeriodItem key={index} period={item} onDelete={handleDelPeriod} onEdit={handleEditPeriod} onChargerManuel={handleChargerManuelRefPeriod} />
                        ))
                    }
                    {
                        Boolean(!data.length) && (
                            <TableRow hover >
                                <TableCell colSpan={7} sx={{textAlign : 'center'}}>Aucune période trouvé</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>);
}

export default PeriodList;