import { FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

KpiParamFilter.propTypes = {
    onChange : PropTypes.func,
    filters : PropTypes.object,
    codeMetierList : PropTypes.array,
};

function KpiParamFilter(props) {

    const { onChange, codeMetierList } = props;
    const [ codeMetier, setCodeMetier ] = useState('');

    const handleChange = (e) => {
        if (!onChange)   return;
        const { value } = e.target;
        setCodeMetier(value);
        onChange(value);
    };

    return (
        <Grid container spacing={2} sx={{paddingTop : 3, paddingX : 2}}>
            <Grid item xs={12} md={6}>
                <FormControl variant="outlined" fullWidth margin="dense">
                    <InputLabel shrink id="select-label-nom-job">Codes métier</InputLabel>
                    <Select name="codeMetier" labelId="select-label-nom-job" id="select-nom-job" value={codeMetier} fullWidth variant="outlined" label="Code métier" size="small" onChange={handleChange}>
                        {codeMetierList.length && codeMetierList.map((item, index) => <MenuItem key={index} value={item.code}>{item.code}-{item.libelle}</MenuItem>) }
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );
}

export default KpiParamFilter;