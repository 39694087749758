import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { TextField } from '@mui/material';
import 'date-fns';
import { fr } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';

DesktopDate.propTypes = {
    form: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,

    label: PropTypes.string,
    disabled: PropTypes.bool,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
};

function DesktopDate(props) {

    const { form, name, label, disabled, maxDate, minDate } = props;
    const { formState: { errors } } = form;
    const hasError = errors[name];

    return (
        <Controller
            name={name}
            control={form.control}
            render={({ field: { onChange, value } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={fr}>
                    <DatePicker
                    label={label}
                    id={name}
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    error={!!hasError}
                    maxDate={maxDate}
                    minDate={minDate}
                    renderInput={(params) => <TextField {...params} size="small" defaultValue="" fullWidth />}
                    />
                </LocalizationProvider>
            )}
        />
    );
}

export default DesktopDate;