import SearchIcon from '@mui/icons-material/Search';
import { Grid, Paper, Divider, IconButton, InputBase } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

MetierFilter.propTypes = {
    onChange : PropTypes.func,
    onSearch : PropTypes.func,
    sKey : PropTypes.string,
};

function MetierFilter(props) {

    const { onChange, onSearch, sKey } = props;
    const [ value, setValue ] = useState(sKey);

    const handleChange = (e) => {
        if (!onChange)   return;
        const { name, value } = e.target;
        setValue(value);
        onChange({ [name] : value });
    };

    const handleClick = () => {
        if (!onSearch)  return;
        onSearch();
    };

    return (
        <Grid container spacing={2} sx={{paddingY : 2}}>
            <Grid item container xs={6}>
                <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }} >
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Saisir le libéllé du métier"
                        inputProps={{ 'aria-label': 'Saisir le libéllé du métier', variant : 'outlined' }}
                        onChange={handleChange}
                        fullWidth
                        name='skey'
                        value={value}
                    />
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                    <IconButton type="button" sx={{ p: '10px' }} aria-label="search" onClick={handleClick}>
                        <SearchIcon />
                    </IconButton>
                </Paper>
            </Grid>
        </Grid>
    );
}

export default MetierFilter;