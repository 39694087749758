import { Box, Grid, Paper, TablePagination, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import metierApi from '../../../api/metierApi';
import Loading from '../../../components/Loading';
import Popup from '../../../components/Popup';
import SkeletonList from '../../../components/Skeleton/List';
import useUser from '../../../hook/useUser';
import { getMessageError } from '../../../util';
import MetierAdd from './components/MetierAdd';
import MetierFilter from './components/MetierFilter';
import MetierList from './components/MetierList';

Metier.propTypes = {
};

const ROW_OF_PAGE_DEFAULT = 10;

function Metier() {

    const { enqueueSnackbar } = useSnackbar();
    const [ metierList, setMetierList ] = useState([]);
    const user = useUser();

    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});
    const location = useLocation();
    const history = useHistory();

    const queryParam = useMemo(() => {
        const params = queryString.parse(location.search);
  
        return {
          ...params,
          page : Number.parseInt(params.page) || 0,
          size : Number.parseInt(params.size) || ROW_OF_PAGE_DEFAULT,
        };
    }, [location.search]);

    const [ rowsPerPage, setRowsPerPage ] = useState(queryParam.size || ROW_OF_PAGE_DEFAULT);
    const [ pagination, setPagination ] = useState({ page : 0, count : 1 });
    const [ loading, setLoading ] = useState(false);
    const [ filters, setFilters ] = useState(() => ({ page : 0, size : queryParam.size || ROW_OF_PAGE_DEFAULT }));

    useEffect(() => {
        getList();
    }, [ queryParam ]);

    const getList = async () => {
        setLoading(true);
        try {
            const { page, size, ...params } = queryParam;
            const { items, totalItems, currentPage } = await metierApi.list({ page, size, params });
            setMetierList(items);
            setPagination({ count : totalItems, page : currentPage });
        } catch (error) {
            const msg = getMessageError(error, 'Échec de l\'obtention de la liste de métier, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilter) => {
        setFilters({...filters, ...newFilter });
    };

    const pushNewParams = newfilters => {
        history.push({
            pathname : history.location.pathname,
            search : queryString.stringify(newfilters),
        });
    };

    const handlePageChange = (e, page) => {
        const newfilters = {...queryParam, page };
        pushNewParams(newfilters);
    };

    const handleClick = () => {
        const newfilters = {...queryParam, page : 0, ...filters };
        pushNewParams(newfilters);
    };

    const handleChangeRowsPerPage = (event) => {
        const size = parseInt(event.target.value, 10);
        const newfilters = {...queryParam, size, page : 0 };
        pushNewParams(newfilters);
        setRowsPerPage(size);
    };

    return (<>
        { loading && <Loading/> }
        <Box>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Box sx={{marginTop : 5, fontWeight : 'bold', fontSize : '20px'}}>
                        <Paper elevation={1} sx={{padding : 2}}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography component="span" variant="h6" sx={{fontWeight: 'bold', color : 'paragraph.main'}}>{'Recherche d\'un métier'}</Typography>
                                <MetierAdd callback={getList} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} user={user} />
                            </Box>
                            <MetierFilter filters={filters} onChange={handleFilterChange} onSearch={handleClick} sKey={queryParam.skey} />
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <Paper elevation={1} sx={{padding : 2}}>
                        {loading 
                        ? <SkeletonList length={9} /> 
                        : <>
                            <MetierList data={metierList} callback={getList} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} />
                            <Box display="flex" flexFlow="row nowrap" justifyContent="center" sx={{marginTop : 2.5, paddingBottom : 1}} >
                                <TablePagination 
                                    rowsPerPageOptions={[3, 10, 20, 50]}
                                    component="div"
                                    count={pagination.count}
                                    rowsPerPage={rowsPerPage}
                                    page={pagination.page}
                                    onPageChange={handlePageChange}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    labelRowsPerPage="Nombre de ligne sur page"
                                />
                            </Box>
                        </>
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        <Popup open={openPopup} {...paramPopup} />
    </>);
}

export default Metier;