export const ERROR_CODE_USER = {
    USER_NOT_EXISTE : { code : 'USER_NOT_EXISTE', msg : 'Cet identifiant n\'existe pas dans le système'},
    USER_HAS_EXISTE : { code : 'USER_HAS_EXISTE', msg : 'Cet identifiant a déjà ajouté'},
    HAVE_NOT_PERIMET_ACCESS : { code : 'HAVE_NOT_PERIMET_ACCESS', msg : 'Vous n\'êtes pas encore la configuration de périmètre d\'accès, veuillez demander l\'administrateur pour résoudre le problème.'},
};

export const ERROR_CODE_PERIOD = {
    PERIOD_HAS_EXISTE : { code : 'PERIOD_HAS_EXISTE', msg : 'Cette période est déjà créée'},
};

export const ERROR_CODE_JOB = {
    JOB_PERIOD_EXISTE : { code : 'JOB_PERIOD_EXISTE', msg : 'Ce job est déjà créée'},
    JOB_PERIOD_ATRAITER_OR_ENCOURS : { code : 'JOB_PERIOD_ATRAITER_OR_ENCOURS', msg : 'Il existe un autre job qui est à traiter ou en cours de chargement'},
};

export const ERROR_GOLOBAL = {
    SQL_CONNECT : { code : 'SQL_CONNECT', msg : 'Une erreur est survenue, veuillez déclarer un incident sur Easyvista. Merci.'},
};

export default { ...ERROR_CODE_USER, ...ERROR_CODE_PERIOD, ...ERROR_CODE_JOB, ...ERROR_GOLOBAL };