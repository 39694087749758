import { SecureRoute } from '@okta/okta-react';
import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router';
import NotFound from '../../components/NotFound';
import Job from './Job';
import Metier from './Metier';
import Parametrage from './Parametrage';
import Period from './Period';
import User from './User';
import Kpi from './Kpi';

function Administration() {

    const match = useRouteMatch();

    return (
        <Switch>
            <SecureRoute exact path={`${match.url}/user`} component={User} />
            <SecureRoute exact path={`${match.url}/period`} component={Period} />
            <SecureRoute exact path={`${match.url}/metier`} component={Metier} />
            <SecureRoute exact path={`${match.url}/job`} component={Job} />
            <SecureRoute exact path={`${match.url}/params`} component={Parametrage} />
            <SecureRoute exact path={`${match.url}/kpi-params`} component={Kpi} />
            <Route component={NotFound} />
        </Switch>
    );
}

export default Administration;