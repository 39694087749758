import { Container, CssBaseline, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SecureRoute } from '@okta/okta-react';
import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import Administration from '../../features/Administration';
import Export from '../../features/Export';
import Home from '../../features/Home';
import Header from '../Header';
import Logo from '../Logo';
import NotFound from '../NotFound';
import Sidebar from './../Sidebar';

const useStyles = makeStyles((theme) => ({
    content : {
        flexGrow: 1,
        width : '100%',
        backgroundColor : theme.palette.background.main
    },
    toolbar: theme.mixins.toolbar,
}));

function PrivateRoute() {

    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return (
        <Box display="flex">
            <CssBaseline />
            <Header onToggle={handleDrawerToggle} />
            <Logo />
            <Sidebar open={mobileOpen} onToggle={handleDrawerToggle} />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Container>
                    <Switch>
                        <SecureRoute exact path="/" component={Home} />
                        <SecureRoute exact path="/export" component={Export} />
                        <SecureRoute path="/administration" component={Administration} />
                        <Route component={NotFound} />
                    </Switch>
                </Container>
            </main>
        </Box>
    );
}

export default PrivateRoute;