import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import CheckboxField from '../../../../components/form-controls/CheckboxField';
import InputField from '../../../../components/form-controls/InputField';
import { NB_DECALAGE_X_CODE, NB_DECALAGE_Y_CODE } from '../../../../constants';
import useParams from '../../../../hook/useParams';

PeriodAddForm.propTypes = {
    onSubmit : PropTypes.func,
};

function PeriodAddForm(props) {
    const { onSubmit } = props;

    const [ disabled, setDisabled ] = useState(false);
    const params = useParams();
    const [ x, y ] = [ NB_DECALAGE_X_CODE, NB_DECALAGE_Y_CODE ].map(item => {
        const param = params.find(p => p.code === item);
        return param ? param.value : null;
    });

    const labelApplyAjuste = `Calcul automatique des dates ${x ? `d'ouverture (début de période + ${x} j) et `: ''} ${y ? `de cloture (fin de période + ${y} j)`: ''}`;

    const schema = yup.object().shape({
        month: yup.number().nullable().max(12, 'Valeur maximal est 12').min(1, 'Valeur minimal est 1').typeError('Veuillez saisir un numéro').when('genAll', {
            is : false,
            then : yup.number().max(12, 'Valeur maximal est 12').min(1, 'Valeur minimal est 1').typeError('Veuillez saisir un numéro').required('Veuillez saisir le mois'),
        }),
        year: yup.number().required('Veuillez choisir le\'année').typeError('Veuillez saisir un numéro').test('len', 'Must be exactly 5 characters', val => `${val}`.length === 4),
        genAll: yup.bool(),
        applyAjustable: yup.bool(),
    });

    const form = useForm({
        defaultValues: {
            genAll : false,
            applyAjustable : false,
        },
        resolver : yupResolver(schema),
    });

    const handleSubmit = (values) => {
        if (!onSubmit)  return;
        onSubmit({ ...values });
    };

    const handleChange = (isGenAll) => {
        setDisabled(isGenAll);
    };

    return (
        <Box sx={{marginTop :2, fontWeight : 'bold', fontSize : '20px'}}>
            <form onSubmit={form.handleSubmit(handleSubmit)} sx={{margin : 1}} id="form-modify" >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <InputField disabled={disabled} name="month" label="Mois" form={form} variant="outlined" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputField name="year" label="Année" form={form} variant="outlined" />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxField name="genAll" label="Génération de toutes les périodes de cette année" form={form} variant="outlined" onChange={handleChange} />
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxField name="applyAjustable" label={labelApplyAjuste} form={form} variant="outlined" />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display="none">
                        <Button variant="contained" color="primary" type="submit" id="form-submit"> Enregistrer </Button>
                    </Box>
                </Grid>
            </form>
        </Box>
    );
}

export default PeriodAddForm;