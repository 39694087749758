import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

MenuMore.propTypes = {
    options : PropTypes.array,
};

const ITEM_HEIGHT = 48;

function MenuMore({ options = [] }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
        <IconButton
            aria-label="more"
            id="long-button"
            aria-controls="long-menu"
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            sx={{padding : 0}}
        >
            <MoreHorizIcon />
        </IconButton>
        <Menu
            id="long-menu"
            MenuListProps={{
                'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '32ch',
                },
            }}
        >
            {options.filter(item => item.show).map(({ title, onClick, icon : IconItem, color }, index) => (
                <MenuItem sx={{fontSize : 'inherit'}} key={index} onClick={() => {
                    if (onClick) onClick();
                    handleClose();
                }}>
                    {
                        IconItem && 
                        <Tooltip title={title}>
                            <IconButton aria-label={title} size="small" sx={{marginRight : 1}}>
                                <IconItem sx={{color}} fontSize="inherit" />
                            </IconButton>
                        </Tooltip>
                    }
                    {title}
                </MenuItem>
            ))}
        </Menu>
        </div>
    );
}

export default MenuMore;