import BlockIcon from '@mui/icons-material/Block';
import { TableCell, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import MenuMore from '../../../../components/MenuMore';
import { JOB_PERIOD } from '../../../../constants';
import { translateDate, formatDate } from '../../../../util';

JobItem.propTypes = {
    job : PropTypes.object,
    onDel : PropTypes.func,
};

function JobItem(props) {

    const { job, onDel } = props;
    const { palette : { tertiary } } = useTheme();

    const OPTIONS = [
        { title : 'Supprimer', onClick : () => onDel(job), icon : BlockIcon, color: tertiary.main, show : job.status === JOB_PERIOD.A_TRAITER.code },
    ];

    return (
        <TableRow hover>
            <TableCell>{translateDate(job.periodLibelle)}</TableCell>
            <TableCell>{formatDate(job.datePeriod)}</TableCell>
            <TableCell>{job.nomJobLibelle}</TableCell>
            <TableCell>{JOB_PERIOD[job.status].label}</TableCell>
            <TableCell>{formatDate(job.dateDemande)}</TableCell>
            <TableCell>{formatDate(job.dateTraitement)}</TableCell>
            <TableCell>{job.userDemande}</TableCell>
            <TableCell align="center">
                { OPTIONS.some(o => o.show) && <MenuMore options={OPTIONS} /> }
            </TableCell>
        </TableRow>
    );
}

export default JobItem;