import { Box, Grid, Paper, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getMessageError } from '../../util';
import kpiApi from './../../api/kpiApi';
import Loading from './../../components/Loading';
import Popup from './../../components/Popup';
import SkeletonList from './../../components/Skeleton/List';
import ExportExcel from './components/ExportExcel';
import ExportFilter from './components/ExportFilter';
import ExportList from './components/ExportList';

Export.propTypes = {
};

function Export() {

    const { enqueueSnackbar } = useSnackbar();

    const [ kpiList, setKpiList ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ filters, setFilters ] = useState(() => ({}));
    const [ openPopup, setOpenPopup ] = useState(false);
    const [ paramPopup, setParamPopup ] = useState({});

    const location = useLocation();
    const history = useHistory();

    const queryParam = useMemo(() => {
        const params = queryString.parse(location.search);
        return { ...params };
    }, [location.search]);

    useEffect(() => {
        getList();
    }, [ queryParam ]);

    const getList = async () => {
        const {...params } = queryParam;
        if (!params.periodUuid) return;

        setLoading(true);
        try {
            const res = await kpiApi.listAllAjust(params.periodUuid);
            setKpiList(res);
        } catch (error) {
            const msg = getMessageError(error, 'Échec de l\'obtention de la liste de kpi, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleFilterChange = (newFilter) => {
        const filterUpdate = {...filters, ...newFilter };
        setFilters(filterUpdate);
        pushNewParams(filterUpdate);
    };

    const pushNewParams = newfilters => {
        history.push({
            pathname : history.location.pathname,
            search : queryString.stringify(newfilters),
        });
    };

    return (<>
        { loading && <Loading/> }
        <Box>
            <Grid container spacing={3}>
                <Grid xs={12} item>
                    <Box sx={{marginTop : 5, fontWeight : 'bold', fontSize : '20px'}}>
                        <Paper elevation={1} sx={{padding : 2}}>
                            <Box display="flex" justifyContent="space-between">
                                <Typography component="span" variant="h6" sx={{fontWeight: 'bold', color : 'paragraph.main'}}>{'Choisir une période'}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="space-between">
                                <Grid container spacing={2}>
                                    <Grid item xs={9}>
                                        <ExportFilter onChange={handleFilterChange} setOpenPopup={setOpenPopup} setParamPopup={setParamPopup} periodUuid={queryParam.periodUuid} />
                                    </Grid>
                                    <Grid item xs={3} sx={{display: 'flex', justifyContent : 'center', alignItems : 'center'}}>
                                        { !!kpiList.length && <ExportExcel data={kpiList} /> }
                                    </Grid>
                                </Grid>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
                <Grid xs={12} item>
                    <Paper elevation={1} sx={{padding : 2}}>
                        {loading 
                        ? <SkeletonList length={9} /> 
                        : <ExportList data={kpiList} />
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        <Popup open={openPopup} {...paramPopup} />
    </>);
}

export default Export;