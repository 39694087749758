import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import metierApi from '../../../../api/metierApi';
import Loading from '../../../../components/Loading';
import { getMessageError } from '../../../../util';
import MetierFormModif from './MetierFormModif';
import MetierItem from './MetierItem';

MetierList.propTypes = {
    data : PropTypes.array,
    callback : PropTypes.func,
    setOpenPopup : PropTypes.func,
    setParamPopup : PropTypes.func,
};

const getPopupContent = (metier, action) => {
    switch (action){
        case 'ACTIVER' : return <>{'Vous êtes sûr d\'activer '} <b>{metier.code} - {metier.libelle}</b> </>;
        case 'DESACTIVER' : return <>{'Vous êtes sûr de désactiver '} <b>{metier.code} - {metier.libelle}</b></>;
        default : '';
    }
};

function MetierList(props) {
    const { data = [], callback, setOpenPopup, setParamPopup } = props;
    const { enqueueSnackbar } = useSnackbar();
    const [ loading, setLoading ] = useState(false);

    const handleState = async (metier, values, action) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Information',
            content : getPopupContent(metier, action),
            buttons : [
                {label : 'Mettre à jour', color : 'button.main', onClick : () => toggleState(metier.uuid, values)},
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const handleEdit = async (metier) => {
        setOpenPopup(true);
        setParamPopup({
            title : 'Modifier la période',
            form : <MetierFormModif metier={metier} onSubmit={toggleState} />,
            buttons : [
                {label : <label htmlFor='form-submit'>Mettre à jour</label>, color : 'button.main' },
                {label : 'Annuler', color : 'inherit', onClick : () => setOpenPopup(false)},
            ]
        });
    };

    const toggleState = async (uuid, values) => {
        setLoading(true);
        try {
            await metierApi.edit(uuid, values);
            enqueueSnackbar('Mettre à jour l\'information avec succès.', { variant: 'success' });
            callback && callback();
        } catch (error) {
            const msg = getMessageError(error, 'Une erreur est survenue en mise à jour cet utilisateur, veuilez contacter avec le support');
            enqueueSnackbar(msg, { variant: 'error' });
        } finally {
            setLoading(false);
            setOpenPopup(false);
            setParamPopup({});
        }
    };

    return (<>
        { loading && <Loading/> }
        <TableContainer component={Paper}>
            <Table sx={{minWidth : 650}} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{fontWeight : 'bold'}}>Code</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Libéllé</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>{'Date d\'effet'}</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>{'Date de fin d\'effet'}</TableCell>
                        <TableCell sx={{fontWeight : 'bold'}}>Statut</TableCell>
                        <TableCell align="center" sx={{fontWeight : 'bold'}}>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        Boolean(data.length) && data.map((metier, index) => (
                            <MetierItem key={index} metier={metier} onToggle={handleState} onEdit={handleEdit} />
                        ))
                    }
                    {
                        Boolean(!data.length) && (
                            <TableRow hover >
                                <TableCell colSpan={6} sx={{textAlign : 'center'}}>Aucun métier trouvé</TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    </>);
}

export default MetierList;