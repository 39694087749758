import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Box, Card, CardActions, CardContent, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { PROFILES_LABELS } from '../../constants';
import {getMessageError, getUserInfo, logout} from './../../util';
import packageJson from '../../../package.json';
import homeApi from '../../api/homeApi';
import { useSnackbar } from 'notistack';

Profile.propTypes = {
    options : PropTypes.array,
};

const ITEM_HEIGHT = 48;

function Profile() {

    const [anchorEl, setAnchorEl] = useState(null);
    const [ versionBack, setVersionBack ] = useState(null);
    const open = Boolean(anchorEl);

    const user = getUserInfo();
    const { enqueueSnackbar } = useSnackbar();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        (async () => {
            try {
                const res = await homeApi.getVersion();
                setVersionBack(res);
            } catch (error) {
                const msg = getMessageError(error, 'Échec de récupération de la version du back');
                enqueueSnackbar(msg, { variant: 'warning' });
            }
        })();
    });


    return (<>
        <Tooltip title="Profile">
            <IconButton size="medium" aria-label="Profile" id="long-button"aria-controls="long-menu"aria-expanded={open ? 'true' : undefined}aria-haspopup="true"onClick={handleClick}sx={{padding : 0,}}>
                <AccountBoxIcon sx={{color : 'button.main'}}/>
            </IconButton>
        </Tooltip>
        <Menu
            id="long-menu"
            MenuListProps={{'aria-labelledby': 'long-button' }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '28ch',
                },
            }}
        >
            <MenuItem sx={{padding : 0}} onClick={handleClose}>
                <Box>
                    <Card sx={{boxShadow : 'none'}}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14, fontWeight:'bold', color : 'headline.main' }} gutterBottom>
                                {user.identifiant} ({user.prenom} {user.nom})
                            </Typography>
                            <Typography sx={{color : 'paragraph.main'}}>
                                {PROFILES_LABELS[user.profile]}
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardContent>
                            <Typography sx={{ fontSize: 12, color : 'headline.main' }} gutterBottom>
                                Version Front: {packageJson.version}
                            </Typography>
                            <Typography sx={{ fontSize: 12, color : 'headline.main' }}>
                                Version Back: {versionBack}
                            </Typography>
                        </CardContent>
                        <Divider />
                        <CardActions sx={{paddingBottom : 0}}>
                            <Typography sx={{color : 'tertiary.main', fontSize: '15px', paddingY : 0.5, paddingX : 1}} onClick={logout}>Déconnexion</Typography>
                        </CardActions>

                    </Card>
                </Box>
            </MenuItem>
        </Menu>
    </>);
}

export default Profile;